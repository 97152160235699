import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  updateConfig,
} from "../../../store/slices/TermsAndCondition/termsAndConditionSlice.js";
import { useGetListDataQuery } from "../../../store/queries/termsAndCondition";

const useTermsAndCondition = (dashboard) => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.termsAndCondition);
  const [skip, setSkip] = useState(true);

  const { showEditModal, selctedId } = useSelector((state) => state.termsAndCondition);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    type: 1
  });

  if(mainData) {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = mainData?.data?.result;
      })
    );
  }

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = true;
        state.is_edit = true;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };


  return {
    mainData,
    menuState,
    isFetching,
    isLoading,
    showEditModal,
    refetch,
    closeModal,
    handleEditClick,
    closeEditModal,
  };
};

export default useTermsAndCondition;
