import { useSearchParams } from "react-router-dom";

const usePrintInvoice = (item) => {
  const [params] = useSearchParams();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const billingDetailLabel = {
    tax: "Tax",
    discount: "Discount",
    shipping_charge: "Shipping Charge",
    extra_charge: "Extra Charge",
    subTotal: "Sub Total"
  };

  const billingDetailValue = {
    tax: `${
        item?.tax ?? "Not specified"
    }`,
    discount: `${item?.discount ?? "Not specified"}`,
    shipping_charge: `${
        item?.shipping_charge ?? "Not specified"
      }`,
      extra_charge: `${
        item?.extra_charge ?? "Not specified"
      }`,
      subTotal: `${
        item?.grand_total ?? "Not specified"
      }`,
  };

  const billingDetails = Object.keys(billingDetailValue).map((key) => {
    return {
      label: billingDetailLabel[key],
      value:
        typeof billingDetailValue[key] === "string"
          ? billingDetailValue?.[key]
          : typeof billingDetailValue[key] === "number"
          ? billingDetailValue?.[key]
          : billingDetailValue?.[key]?.name,
    };
  });

  

  return {
    billingDetails
  };
};

export default usePrintInvoice;
