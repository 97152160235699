import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
	updateConfig,
	createNewEntry,
} from "../../../../store/slices/Blog/blogSlice.js";

import { useGetBasicDataQuery } from "../../../../store/queries/blog";
import DOMPurify from "dompurify";
import {
	ContentState,
	EditorState,
	convertFromHTML,
	convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

const useBlogForm = ({ refetch, closeModal }) => {

	const BASE_URL =
  process.env.REACT_APP_PRODUCTION === "TRUE"
    ? process.env.REACT_APP_PRODURL
    : process.env.REACT_APP_APIURL;
	const dispatch = useDispatch();

	const profilefileInputRef = useRef(null);

	const { is_edit, selectedId, selectedItemsDetails } = useSelector((state) => state.blog);

	const [imageCoverPreview, setImageCoverPreview] = useState(
		selectedItemsDetails !== "" ? selectedItemsDetails?.image_url : ""
	);
	const { data: CategoryData = {}, isSuccess: isOptionDatas } = useGetBasicDataQuery({});

	const handleContentChange = (editorsState) => {
		formik.setFieldValue("description", editorsState);
	};

	useEffect(() => {
		if (selectedItemsDetails !== "") {
			setImageCoverPreview(selectedItemsDetails?.image_url ?? "");
		}
	}, [selectedItemsDetails]);


	const validation = Yup.object({
		title: Yup.string()
			.min(2, "The title must be at least 2 characters")
			.required("*Required"),
		blog_category_id: Yup.string()
			.required("*Required"),
		image: is_edit ? Yup.string() : Yup.string().required('*Required'),

	});

	const formik = useFormik({
		initialValues: {
			meta_title:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.meta_title
					: "",
			title:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.title
					: "",
			meta_description:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.meta_description !== null
						? selectedItemsDetails?.meta_description
						: ""
					: "",
			blog_category_id:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.blog_category_id !== null
						? selectedItemsDetails?.blog_category_id
						: ""
					: "",
			status:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.status !== null
						? selectedItemsDetails?.status
						: 0
					: 0,
			image: "",

		},

		validationSchema: validation,
		enableReinitialize: true,

		validate: (values) => {
			const errors = {};

			// Convert editor content to plain text
			const plainText = values.description.getCurrentContent().getPlainText();

			// Check if the content is empty or only contains whitespace
			if (!plainText.trim()) {
				errors.description = "*Description cannot be empty";
			}

			return errors;
		},
		onSubmit: (values, { resetForm }) => {
			const contentState = values.description.getCurrentContent();
			const contentRaw = convertToRaw(contentState);
			const plainText = draftToHtml(contentRaw);

			let obj = {
				title: values.title,
				meta_title: values.meta_title ? values.meta_title : "",
				meta_description: values.meta_description ? values.meta_description : "",
				blog_category_id: values.blog_category_id ? values.blog_category_id : "",
				status: +values.status,
				description: plainText,
			};

			if (is_edit) {
				obj.id = selectedId;
			}

			if (values.image) {
				obj.image = values.image;
			}

			let formData = new FormData();

			Object.keys(obj).forEach((key) => {
				return formData.append(key, obj[key]);
			});


			dispatch(createNewEntry(formData)).then((response) => {
				if (response?.payload?.status_code === 200) {
					resetForm();
					refetch();
					closeModal?.();
					dispatch(
						updateConfig((state) => {
							state.showCreateModal = false;
						})
					);
					if (is_edit) {
						dispatch(
							updateConfig((state) => {
								state.clearSelection = true;
							})
						);
					}
					toast.success(response?.payload?.message);
				} else if (response?.payload?.status_code === 400) {
					formik.setErrors(response?.payload?.message);
				} else toast.error(response?.payload?.message);
			});
		},
	});

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showCreateModal = false;
			})
		);
	};

	const handleCoverImage = (e) => {
		const files = e.target.files;
	  
		if (files?.[0]?.size / (1024 * 1024) <= 5) {
		  const allowedExtensions = ["jpeg", "jpg", "png"];
		  const file = e.target.files[0];
		  const fileExtension = file.name.split('.').pop().toLowerCase();
	  
		  if (allowedExtensions.includes(fileExtension)) {
			formik.setFieldValue("image", e?.target?.files?.[0]);
	  
			const reader = new FileReader();
	  
			reader.onloadend = () => {
			  setImageCoverPreview(reader.result);
			};
	  
			if (file) {
			  reader.readAsDataURL(file);
			}
		  } else {
			formik.setFieldError("image", "The image must be a .jpeg, .jpg, or .png file.");
		  }
		} else if (files?.[0]?.size / (1024 * 1024) > 5) {
		  formik.setFieldError("image", "The image must be less than 5MB in size.");
		}
	  };

	  const defaultHtmlContent = selectedItemsDetails?.description;

	  const sanitizedHtml = DOMPurify.sanitize(defaultHtmlContent);
	  const contentState = ContentState.createFromBlockArray(
		  convertFromHTML(sanitizedHtml)
	  );
	  const editorStates = EditorState.createWithContent(contentState);
  
	  useEffect(() => {
		  if (!formik.values.description) {
			  formik.setFieldValue("description", editorStates);
		  }
	  }, [editorStates]);



	function uploadImageCallback(file) {

		return new Promise(
			(resolve, reject) => {
				const xhr = new XMLHttpRequest();
				xhr.open('POST', `${BASE_URL}/v1/admin/upload-common-image`);
				xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('USER_ACCESS_TOKEN')}`);
				const data = new FormData();
				data.append('image', file);
				xhr.send(data);
				xhr.addEventListener('load', () => {
					const response = JSON.parse(xhr.responseText);
					setTimeout(() => {
						const uploadedImage = {
							data: {
								link: `${response?.data?.image_url}`
							}
						};
						resolve(uploadedImage);
					}, 2000);
				});
				xhr.addEventListener('error', () => {
					const error = JSON.parse(xhr.responseText);
					reject(error);
				});
			}
		)

	};


	return {
		formik,
		imageCoverPreview,
		selectedItemsDetails,
		profilefileInputRef,
		handleCoverImage,
		handleCloseModal,
		CategoryData,
		handleContentChange,
		uploadImageCallback
		// editorStates
	};
};

export default useBlogForm;
