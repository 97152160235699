import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useCMS = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Home Banners",
      link: "/cms/banners",
      icon: <span className="material-symbols-outlined x4"> ad </span>,
      active: checkIfActiveRoute("/cms/banners", true),
    },
    {
      title: "Home Video",
      link: "/cms/video",
      icon: <span className="material-symbols-outlined x4"> smart_display </span>,
      active: checkIfActiveRoute("/cms/video", true),
    },
    {
      title: "Frame Banners",
      link: "/cms/frame-banners",
      icon: <span className="material-symbols-outlined x4"> photo_frame </span>,
      active: checkIfActiveRoute("/cms/frame-banners", true),
    },
    {
      title: "Testimonials",
      link: "/cms/testimonials",
      icon: <span className="material-symbols-outlined x4"> badge </span>,
      active: checkIfActiveRoute("/cms/testimonials", true),
    },
    {
      title: "FAQ",
      link: "/cms/faq",
      icon: <span className="material-symbols-outlined x4"> quiz </span>,
      active: checkIfActiveRoute("/cms/faq", true),
    },
    {
      title: "Blogs",
      link: "/cms/blog",
      icon: <span className="material-symbols-outlined x4"> rss_feed </span>,
      active: checkIfActiveRoute("/cms/blog", true),
    },
    {
      title: "Privacy Policy",
      link: "/cms/privacy-policy",
      icon: <span className="material-symbols-outlined x4"> privacy_tip </span>,
      active: checkIfActiveRoute("/cms/privacy-policy", true),
    },
    {
      title: "Return Policy",
      link: "/cms/return-policy",
      icon: <span className="material-symbols-outlined x4"> policy </span>,
      active: checkIfActiveRoute("/cms/return-policy", true),
    },
    {
      title: "Terms And Condition",
      link: "/cms/terms-and-condition",
      icon: <span className="material-symbols-outlined x4"> conditions </span>,
      active: checkIfActiveRoute("/cms/terms-and-condition", true),
    },
    {
      title: "Shipping Policy",
      link: "/cms/shipping-policy",
      icon: <span className="material-symbols-outlined x4"> conditions </span>,
      active: checkIfActiveRoute("/cms/shipping-policy", true),
    },
    {
      title: "Company Details",
      link: "/cms/company-details",
      icon: <span className="material-symbols-outlined x4"> add_business </span>,
      active: checkIfActiveRoute("/cms/company-details", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.submenus?.map((side_menu) => side_menu.name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    // drawerMenu: drawerMenuPermission,
    drawerMenu: drawerMenuPermission,
  };
};

export default useCMS;
