import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortById: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false, // New variable for active status
  startDate: "", // New variable for start date
  endDate: ""
};

export const getBasicData = createAsyncThunk(
  "/v1/admin/order/cancel/basic-data",
  async (param,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/admin/order/cancel/basic-data`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "/v1/admin/order/cancel/status-change",
  async (param,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/order/cancel/status-change`,param);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cancelRequestSlice = createSlice({
  name: "cancelRequest",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.sortById = action.payload.actionId;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    resetExtraFilters: (state, action) => {
      state.sortById = "";
      state.startDate = "";
      state.endDate = "";
    },
  },
  
});

export const { 
  updateConfig,
  resetExtraFilters,
  setStoreFormValues,
 } = cancelRequestSlice.actions;

export default cancelRequestSlice.reducer;
