import React from "react";
import ReactDOM from "react-dom/client";

import { configureStore } from "@reduxjs/toolkit";
import App from "./components/App";
import { Provider } from "react-redux";
import appReducer from "./store";
import { enquiries } from "./store/queries/enquiries";
import { bulkEnquiries } from "./store/queries/bulkEnquiries";
import { settings } from "./store/queries/settings";
import { email } from "./store/queries/email";
import { dashboard } from "./store/queries/Dashboard";
import { colors } from "./store/queries/colors";
import { size } from "./store/queries/size";
import { hangType } from "./store/queries/hangType";
import { finish } from "./store/queries/finish";
import { type } from "./store/queries/type";
import { orders } from "./store/queries/orders";
import { nonSeasonalOffers } from "./store/queries/nonSeasonalOffers";
import { seasonBasedOffers } from "./store/queries/seasonBasedOffers";
import { FAQ } from "./store/queries/faq";
import { blog } from "./store/queries/blog";
import { banners } from "./store/queries/banners";
import { testimonials } from "./store/queries/testimonials";
import { frameBanners } from "./store/queries/frameBanners";
import { filterForm } from "./store/queries/filterForm";
import { privacyPolicy } from "./store/queries/privacyPolicy";
import { termsAndCondition } from "./store/queries/termsAndCondition";
import { returnPolicy } from "./store/queries/returnPolicy";
import { profile } from "./store/queries/profile";
import { transactions } from "./store/queries/transactions";
import { cancelRequest } from "./store/queries/cancelRequest";
import { notificationRtk } from "./store/queries/notifications";
import { shippingCharge } from "./store/queries/shippingCharge";
import { homeVideo } from "./store/queries/homeVideos";
import { companyDetails } from "./store/queries/companyDetails";
import { clusters } from "./store/queries/clusters";
import { shippingPolicy } from "./store/queries/shippingPolicy";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      enquiries.middleware,
      bulkEnquiries.middleware,
      filterForm.middleware,
      // settings.middleware,
      type.middleware,
      colors.middleware,
      size.middleware,
      hangType.middleware,
      finish.middleware,
      // email.middleware,
      dashboard.middleware,
      orders.middleware,
      nonSeasonalOffers.middleware,
      seasonBasedOffers.middleware,
      FAQ.middleware,
      blog.middleware,
      banners.middleware,
      testimonials.middleware,
      frameBanners.middleware,
      privacyPolicy.middleware,
      termsAndCondition.middleware,
      returnPolicy.middleware,
      profile.middleware,
      transactions.middleware,
      cancelRequest.middleware,
      notificationRtk.middleware,
      shippingCharge.middleware,
      homeVideo.middleware,
      companyDetails.middleware,
      clusters.middleware,
      shippingPolicy.middleware,
      settings.middleware
      ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
