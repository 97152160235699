import { Button } from "@wac-ui-dashboard/wac_component_library";
import useShippingCharge from "./useShippingCharge";
import "./shippingCharge.module.scss";
import EmptyData from "../../Global/EmptyData";
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer";

const ShippingCharge = () => {
    const {
        mainData,
        isFetching,
        editItem,
        newItem,
        setNewItem,
        handleAddClick,
        handleSaveClick,
        handleCancelClick,
        setEditItem,
        handleEditClick,
        handleDeleteClick,
        menuItems,
        handleRateSave,
    } = useShippingCharge();

    return (
        <>
            {isFetching ? (
                <div className="pro-p-4 pro-w-100">
                    <OverviewShimmer />
                </div>
            ) : (
                <>
                    <div className="pro-d-flex pro-gap-4">
                        <input
                            className="pro-input lg"
                            type="number"
                            placeholder="Range Start At"
                            value={newItem?.range_start}
                            onChange={(e) =>
                                setNewItem({ ...newItem, range_start: e.target.value })
                            }
                        />
                        <input
                            className="pro-input lg"
                            type="number"
                            placeholder="Range End At"
                            value={newItem?.range_end}
                            onChange={(e) =>
                                setNewItem({ ...newItem, range_end: e.target.value })
                            }
                        />
                        <input
                            className="pro-input lg"
                            type="number"
                            placeholder="Rate"
                            value={newItem?.rate}
                            onChange={(e) => setNewItem({ ...newItem, rate: e.target.value })}
                        />
                        <button className="pro-btn pro-btn-primary pro-items-center pro-px-6" onClick={handleAddClick}>Add</button>
                    </div>
                    <div className="pro-pt-5">
                        {menuItems?.length === 0 ? (
                            <EmptyData />
                        ) : (
                            <table className="pro-table">
                                <thead>
                                    <tr>
                                        <th>Range Start At</th>
                                        <th>Range End At</th>
                                        <th>Rate</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {menuItems?.map((item) => (
                                        <tr key={item?.id}>
                                            <td>
                                                {editItem && editItem?.id === item?.id ? (
                                                    <input
                                                        className="pro-input"
                                                        type="number"
                                                        value={editItem?.range_start}
                                                        onChange={(e) =>
                                                            setEditItem({
                                                                ...editItem,
                                                                range_start: e.target.value,
                                                            })
                                                        }
                                                    />
                                                ) : (
                                                    item?.range_start
                                                )}
                                            </td>
                                            <td>
                                                {editItem && editItem?.id === item?.id ? (
                                                    <input
                                                        className="pro-input"
                                                        type="number"
                                                        value={editItem?.range_end}
                                                        onChange={(e) =>
                                                            setEditItem({
                                                                ...editItem,
                                                                range_end: e.target.value,
                                                            })
                                                        }
                                                    />
                                                ) : (
                                                    item?.range_end
                                                )}
                                            </td>
                                            <td>
                                                {editItem && editItem?.id === item?.id ? (
                                                    <input
                                                        className="pro-input"
                                                        type="number"
                                                        value={editItem?.rate}
                                                        onChange={(e) =>
                                                            setEditItem({
                                                                ...editItem,
                                                                rate: e.target.value,
                                                            })
                                                        }
                                                    />
                                                ) : (
                                                    item?.rate
                                                )}
                                            </td>
                                            <td>
                                                <div className="pro-d-flex pro-gap-2 pro-justify-center">
                                                    {editItem && editItem?.id === item?.id ? (
                                                        <>
                                                            <button className="pro-btn pro-btn-primary" onClick={handleSaveClick}>Save</button>
                                                            <button className="pro-btn pro-btn-outline" onClick={handleCancelClick}>Cancel</button>
                                                        </>
                                                    ) : (
                                                        <button className="pro-btn pro-btn-outline" onClick={() => handleEditClick(item)}>
                                                            Edit
                                                        </button>
                                                    )}
                                                    <button className="pro-btn pro-btn-link" onClick={() => handleDeleteClick(item)}>
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>

                    <div className={`col-12 pro-d-flex pro-justify-end pro-pt-3`}>
                        <Button
                            className={`pro-btn-primary pro-px-5 lg pro-ms-3 ${false ? "loading" : ""}`}
                            type="submit"
                            // disabled={hasUpdatePermission === true ? false : true}
                            onClick={handleRateSave}
                        >
                            Save
                        </Button>
                    </div>
                </>
            )}
        </>
    )
};

export default ShippingCharge;
