import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    updateShippingCharge
} from "../../../store/slices/ShippingCharge/shippingChargeSlice.js";
import { useGetListDataQuery } from "../../../store/queries/shippingCharge";
import { toast } from "react-toastify";

const useFinish = (dashboard) => {
    const dispatch = useDispatch();
    const menuState = useSelector((state) => state.shippingCharge);

    const [editItem, setEditItem] = useState(null);
    const [menuItems, setMenuItems] = useState(null);
    const [newItem, setNewItem] = useState({ range_start: "", range_end: "", rate: "" });


    const tableRef = useRef(null);

    const {
        data: mainData = {},
        isFetching,
        isLoading,
        refetch,
    } = useGetListDataQuery({
        sort_by: menuState.sortBy,
        sort_order: menuState.sortOrder,
        search: menuState.search,
        per_page: menuState.currentPageSize,
        page: menuState.currentPage,
        is_active: menuState.is_active
    });

    useEffect(() => {
        setMenuItems(mainData?.data?.result);
    }, [mainData]);

    const handleEditClick = (item) => {
        setEditItem({ ...item });
    };

    const handleDeleteClick = (item) => {
        const updatedItemsArray = menuItems.filter((s) => s.id !== item.id);
        setMenuItems(updatedItemsArray);
    };

    const handleAddClick = () => {
        // Add a new student to the list
        if (newItem?.range_start && newItem?.rate) {
            const updatedItemsArray = [
                ...menuItems,
                { ...newItem },
            ];
            setMenuItems(updatedItemsArray);
            setNewItem({ range_start: "", range_end: "", rate: "" });
        }
        else if (newItem?.range_start === null || newItem?.range_start === '') {
            toast.error("Range Start At is Required");
        }
        else if (newItem?.rate === null || newItem?.rate === '') {
            toast.error("Rate is Required");
        }
    };

    const handleSaveClick = () => {
        const updatedItemsArray = menuItems.map((item) => item?.id === editItem?.id ? editItem : item
        );
        if (updatedItemsArray.length > 0) {
            let startAtErr = updatedItemsArray.find((item) => item?.range_start === '')
            let rateErr = updatedItemsArray.find((item) => item?.rate === '')
            if (startAtErr) {
                toast.error("Range Start At is Required");
            }
            else if (rateErr) {
                toast.error("Rate is Required");
            }
            else {
                setMenuItems(updatedItemsArray);
                setEditItem(null);
            }
        }

    };

    const handleCancelClick = () => {
        setEditItem(null);
    };

    const handleRateSave = () => {
        const formData = {
            "shipping_charges": menuItems
        }
        dispatch(
            updateShippingCharge(formData)
        ).then((response) => {
            if (response?.payload?.status_code === 200) {
                setNewItem({ range_start: "", range_end: "", rate: "" });
                refetch();
                toast.success(response?.payload?.message);
            } else if (response?.payload?.status_code === 400) {
                toast.error(response?.payload?.message);
            } else toast.error(response?.payload?.message);
        });
    }



    return {
        mainData,
        isFetching,
        refetch,
        handleEditClick,
        menuItems,
        editItem,
        newItem,
        handleAddClick,
        handleDeleteClick,
        handleSaveClick,
        handleCancelClick,
        setEditItem,
        setNewItem,
        handleRateSave
    };
};

export default useFinish;
