import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import {
  updateConfig 
} from "../../../store/slices/Orders/ordersSlice.js";

const useConfirmation = ({ closeModal, handleFunction, refetch }) => {
  

  const { selectedItemsDetails, is_edit } = useSelector((state) => state.privacyPolicy);
  const dispatch = useDispatch();
  
  const statusOptions = [
    { value: 1, label: "Invoice Bulk Download" },
    { value: 2, label: "Pick List Download" },
  ];

  const formik = useFormik({
    initialValues: {
      type: 0,
    },

    enableReinitialize: true,
    
    onSubmit: async (values) => {

      handleFunction(values.type.value)
    },
  });

  
  return {
    formik,
    statusOptions
  };
};

export default useConfirmation;
