import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  IDs: {},
  selectedItemsDetails: "",
  is_edit: false,
  productDetails: "",
  selectedStatus:"",
};

export const getOrderDataByID = createAsyncThunk(
  "v1/admin/order/detail",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/admin/order/detail?order_id=${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductDataByID = createAsyncThunk(
  "v1/admin/order/product-detail",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/admin/order/product-detail?order_product_id=${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBranches = createAsyncThunk(
  "/v1/branch/store",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/branch/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBranches = createAsyncThunk(
  "/v1/branch/update",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/branch/update/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "/v1/branch/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/branch/delete/${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const synToShip = createAsyncThunk(
  "/v1/admin/shipment/shiprocket/order-push",
  async ( rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/admin/shipment/shiprocket/order-push");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const syncToEship = createAsyncThunk(
  "/v1/admin/shipment/eshipz/order-push",
  async ( rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/admin/shipment/eshipz/order-push");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const bulkDownload = createAsyncThunk(
  "/v1/admin/order/bulk-invoice-downlaod",
  async ( data, rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin/order/bulk-invoice-downlaod", {params: data});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const pickDownload = createAsyncThunk(
  "/v1/admin/order/bulk-invoice-downlaod",
  async ( data, rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin/order/pick-list", {params: data});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const GetBasicData = createAsyncThunk(
  "/v1/admin/order/basic-data",
  async ( rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin/order/basic-data");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    
   builder.addCase(getOrderDataByID.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getOrderDataByID.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    })
    .addCase(getOrderDataByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(getProductDataByID.pending, (state) => {
      state.isProductLoading = true;
      state.error = null;
    })
    .addCase(getProductDataByID.fulfilled, (state, action) => {
      state.productDetails = action.payload.data.data;
      state.isProductLoading = false;
    })
    .addCase(getProductDataByID.rejected, (state, action) => {
      state.isProductLoading = false;
      state.error = action.payload;
    });
    
  },
  
});

export const { updateConfig } = ordersSlice.actions;

export default ordersSlice.reducer;
