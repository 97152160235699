import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useEffect, useMemo } from "react";
import useRoutes from "../../../routes/useRoutes";
import { useDispatch } from "react-redux";
import { getMenuPermissions, updateConfig } from "../../../store/slices/Global";
const useCommonLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuDrawer } = useSelector((state) => state.global);

  const globalState = useSelector((state) => state.global);

  const {
    allowedDashboardComponent,
    allowedEnquiresComponent,
    allowedSettingsComponent,
    allowedFrameComponents,
    allowedOrdersComponents,
    allowedOffersComponent,
    allowedCMSComponent,
    allowedTransactionComponents,
    allowedCancelRequestComponents,
    allowedClusterComponents
  } = useRoutes();
  useEffect(() => {
    if (!globalState.isLogged && location.pathname === "/settings/appearance") {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
    }

    //eslint-disable-next-line
  }, [dispatch, globalState.isLogged]);

  useEffect(() => {
    let sessionLogged = sessionStorage.getItem("USER_ACCESS_TOKEN");

    if (!sessionLogged) {
      sessionStorage.setItem(
        "USER_ACCESS_TOKEN",
        localStorage.getItem("USER_ACCESS_TOKEN")
      );

      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (
      globalState.isLogged === true &&
      (allowedDashboardComponent?.length > 0 ||
        allowedEnquiresComponent?.length > 0 ||
        allowedSettingsComponent?.length > 0)
    ) {
      // setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );
      // }, 1800);


      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedFrameComponents?.length > 0) {
        navigate(
          `/frame/${allowedFrameComponents?.map((item) => item?.path)?.[0]}`
        );
      }  else if (allowedOffersComponent?.length > 0) {
        navigate(
          `/offers/${allowedOffersComponent?.map((item) => item?.path)?.[0]}`
        );
      }  else if (allowedCMSComponent?.length > 0) {
        navigate(
          `/cms/${allowedCMSComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedOrdersComponents?.length > 0) {
        navigate(
          `/${allowedOrdersComponents?.map((item) => item?.path)?.[0]}`
        );
      }  else if (allowedClusterComponents?.length > 0) {
        navigate(
          `/${allowedClusterComponents?.map((item) => item?.path)?.[0]}`
        );
      }  else if (allowedTransactionComponents?.length > 0) {
        navigate(
          `/${allowedTransactionComponents?.map((item) => item?.path)?.[0]}`
        );
      }   else if (allowedCancelRequestComponents?.length > 0) {
        navigate(
          `/${allowedCancelRequestComponents?.map((item) => item?.path)?.[0]}`
        );
      } else {
        navigate(
          `/settings/${
            allowedSettingsComponent?.map((item) => item?.path)?.[0]
          }`
        );
      }
    }

    //eslint-disable-next-line
  }, [
    allowedEnquiresComponent,
    allowedSettingsComponent,
    allowedFrameComponents,
    allowedDashboardComponent,
    allowedOrdersComponents,
    allowedOffersComponent,
    allowedCMSComponent,
    allowedTransactionComponents,
    allowedCancelRequestComponents,
    allowedClusterComponents
  ]);



  const { checkIfActiveRoute } = useRouteUtils();
  const navigations = [
    {
      label: "Dashboard",
      link: `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Frame",
      link: `/frame/${allowedFrameComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/frame`),
    },
    {
      label: "Clusters",
      link: `/${allowedClusterComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/clusters`),
    },
    {
      label: "Orders",
      link: `/${allowedOrdersComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/orders`),
    },
    {
      label: "Offers",
      link: `/offers/${allowedOffersComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/offers`),
    },
    {
      label: "Enquiry",
      link: `/enquiries/${allowedEnquiresComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/enquiries`),
    },
    {
      label: "Transaction",
      link: `/${allowedTransactionComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/transaction`),
    },
    {
      label: "Cancellation Request",
      link: `/${allowedCancelRequestComponents?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/cancelation-request`),
    },
    {
      label: "CMS",
      link: `/cms/${allowedCMSComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/cms`),
    },
    {
      label: "Settings",
      link: `/settings/${
        allowedSettingsComponent?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute("/settings"),
    },
  ];

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };
  const topMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      menu.name,
      // "Dashboard",
    ]);

    return navigations
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    menuDrawer,
    globalState,
    handleGoBack,
  };
};

export default useCommonLayout;
