import React from "react";
import usePrintInvoice from "./usePrintInvoice";
import Assets from "../../../assets/Assets";

const PrintInvoice = ({ item }) => {
    const {
        billingDetails,
        alt
    } = usePrintInvoice(item);

    return (
        <>
            <>
                <div style={{ display: "flex", marginBottom: 25 }}>
                    <div style={{ width: "50%", paddingRight: 8 }}>
                        <div style={{ padding: "16px 16px", display: 'flex', border: "1px solid #EBEFF6", borderRadius: 8, alignItems: "center", justifyContent: "center", boxShadow: "0px 2px 19px 0px rgba(48, 95, 151, 0.07)", height: "100%" }}>
                            <img
                                src={Assets.HEADERLOGO}
                                width={118}
                                height={65}
                                alt={alt || `hang`}
                                style={{ maxWidth: 118, maxHeight: 65, objectFit: "contain" }}
                            />
                        </div>
                    </div>
                    <div style={{ width: "50%", paddingLeft: 8 }}>
                        <div style={{ padding: "16px 14px", display: 'flex', border: "1px solid #EBEFF6", borderRadius: 8, justifyContent: "center", boxShadow: "0px 2px 19px 0px rgba(48, 95, 151, 0.07)" }}>
                            <div style={{ borderRight: "1px solid #EBEFF6", paddingRight: 14, marginRight: 14 }}>
                                <div style={{ marginBottom: 8 }}>
                                    <p style={{ marginBottom: 0, color: "#5D6481" }}>Issued:</p>
                                    <span style={{ color: "#19213D", fontSize: 12, fontWeight: 600 }}>{item?.issued}</span>
                                </div>
                                <div>
                                    <p style={{ marginBottom: 0, color: "#5D6481" }}>Order Number:</p>
                                    <span style={{ color: "#19213D", fontSize: 12, fontWeight: 600 }}>{item?.order_number}</span>
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: 0, color: "#5D6481" }}>Invoice Number:</p>
                                <span style={{ color: "#19213D", fontSize: 12, fontWeight: 600 }}>{item?.invoice_number}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", marginBottom: 25 }}>
                    <div style={{ width: "50%", paddingRight: 8 }}>
                        <h4 style={{ fontSize: 12, fontWeight: 500, marginBottom: 8 }}>Billed By: </h4>
                        <div style={{ backgroundColor: "#EFE9F5", color: "#5D6481", borderRadius: 8, padding: 16 }}>
                            <h6 style={{ fontSize: 12, fontWeight: 600, color: "#19213D", marginBottom: 8 }}>{item?.billed_by?.address_line1}</h6>
                            <p style={{ marginBottom: 3 }}>{item?.billed_by?.address_line2}</p>
                            <p style={{ marginBottom: 3 }}>{item?.billed_by?.address_line3}, {item?.billed_by?.city}, {item?.billed_by?.state}</p>
                            <p style={{ marginBottom: 3 }}>PIN : {item?.billed_by?.pin}</p>
                            <p style={{ marginBottom: 0 }}>GSTIN : {item?.billed_by?.gst_number}</p>
                        </div>
                    </div>
                    <div style={{ width: "50%", paddingLeft: 8 }}>
                        <h4 style={{ fontSize: 12, fontWeight: 500, marginBottom: 8 }}>Billed To: </h4>
                        <div style={{ backgroundColor: "#EFE9F5", color: "#5D6481", borderRadius: 8, padding: 16 }}>
                            <h6 style={{ fontSize: 12, fontWeight: 600, color: "#19213D", marginBottom: 8 }}>{item?.billed_to?.address_line1}</h6>
                            <p style={{ marginBottom: 3 }}>{item?.billed_to?.address_line2}, {item?.billed_to?.city}, {item?.billed_to?.state}</p>
                            <p style={{ marginBottom: 3 }}>{item?.billed_to?.email}</p>
                            <p style={{ marginBottom: 3 }}>PIN : {item?.billed_to?.pin_code}</p>
                            <p style={{ marginBottom: 0 }}>Phone Number : {item?.billed_to?.phone_number}</p>
                        </div>
                    </div>
                </div>
                <table
                    border={0}
                    cellSpacing={0}
                    role="presentation"
                    cellPadding={0}
                    style={{
                        width: "100%",
                        margin: "auto",
                        borderCollapse: "collapse",
                        color: "#5D6481",
                    }}
                >
                    <tbody>
                        <tr>
                            <th
                                style={{
                                    color: "#ffffff",
                                    backgroundColor: "#A42F8A",
                                    paddingTop: "10px",
                                    textAlign: "center",
                                    padding: "13px 5px",
                                    fontSize: 8,
                                    fontWeight: 600,
                                    lineHeight: "1.2",
                                    width: 13
                                }}></th>
                            <th
                                style={{
                                    color: "#ffffff",
                                    backgroundColor: "#A42F8A",
                                    paddingTop: "10px",
                                    textAlign: "center",
                                    padding: "13px 5px",
                                    fontSize: 8,
                                    fontWeight: 600,
                                    lineHeight: "1.2",
                                    width: 160
                                }}
                            >
                                Items
                            </th>
                            <th
                                style={{
                                    color: "#ffffff",
                                    backgroundColor: "#A42F8A",
                                    paddingTop: "10px",
                                    textAlign: "center",
                                    padding: "13px 5px",
                                    fontSize: 8,
                                    fontWeight: 600,
                                    lineHeight: "1.2",
                                    width: 62
                                }}
                            >
                                Quantity
                            </th>
                            <th
                                style={{
                                    color: "#ffffff",
                                    backgroundColor: "#A42F8A",
                                    paddingTop: "10px",
                                    textAlign: "center",
                                    padding: "13px 5px",
                                    fontSize: 8,
                                    fontWeight: 600,
                                    lineHeight: "1.2",
                                    width: 70
                                }}
                            >
                                Rate
                            </th>
                            <th
                                style={{
                                    color: "#ffffff",
                                    backgroundColor: "#A42F8A",
                                    paddingTop: "10px",
                                    textAlign: "center",
                                    padding: "13px 5px",
                                    fontSize: 8,
                                    fontWeight: 600,
                                    lineHeight: "1.2",
                                    width: 145
                                }}
                            >
                                Tax
                            </th>
                            <th
                                style={{
                                    color: "#ffffff",
                                    backgroundColor: "#A42F8A",
                                    textAlign: "center",
                                    padding: "13px 5px",
                                    fontSize: 8,
                                    fontWeight: 600,
                                    lineHeight: "1.2",
                                    width: 64
                                }}
                            >
                                Total
                            </th>
                        </tr>
                        {item?.items?.map((item, index) => (
                            <tr key={index} style={{ backgroundColor: ((index + 1) % 2) !== 0 ? '#EFE9F5' : '#ffffff', borderBottom: "1px solid #D6DCE5" }}>
                                <td style={{ width: 13, padding: "7px 5px", fontSize: 6 }}>
                                    {index + 1}
                                </td>
                                <td
                                    style={{
                                        fontWeight: 600,
                                        lineHeight: "1.2",
                                        fontSize: 7,
                                        padding: "7px 5px",
                                        color: "#000000",
                                    }}
                                >
                                    {item?.product_name}
                                </td>
                                <td
                                    style={{
                                        textAlign: "center",
                                        padding: "7px 5px"
                                    }}
                                >
                                    {item?.quantity}
                                </td>
                                <td
                                    style={{
                                        textAlign: "center",
                                        padding: "7px 5px",
                                    }}
                                >
                                    {item?.amount}
                                </td>
                                {item?.tax?.igst_percentage && (
                                    <td
                                        style={{
                                            textAlign: "center",
                                            padding: "7px 5px",
                                        }}
                                    >
                                        IGST {item?.tax?.igst_percentage}% : {item?.tax?.igst_amount}
                                    </td>
                                )}
                                {item?.tax?.cgst_percentage && (
                                    <td
                                        style={{
                                            textAlign: "center",
                                            padding: "7px 5px",
                                        }}
                                    >
                                        <div>CGST {item?.tax?.cgst_percentage}% : {item?.tax?.cgst_amount}</div>
                                        <div>SGST {item?.tax?.sgst_percentage}% : {item?.tax?.sgst_amount}</div>
                                    </td>
                                )}
                                <td
                                    style={{
                                        textAlign: "center",
                                        padding: "7px 5px",
                                    }}
                                >
                                    {item?.total}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ marginTop: 70, display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        <h6 style={{ fontSize: 10, fontWeight: 600, marginBottom: 5, }}>Total INR : </h6>
                        <div style={{ borderRadius: 8, padding: "18px 21px", display: "inline-block", border: "1px solid #EBEFF6", boxShadow: "0px 1px 3px 0px rgba(25, 33, 61, 0.05)" }}>
                            <h4 style={{ fontSize: 18, fontWeight: 600, marginBottom: 0 }}>{item?.grand_total}</h4>
                        </div>
                    </div>
                    <div style={{ width: "50%" }}>
                        <table
                            border={0}
                            cellSpacing={0}
                            role="presentation"
                            cellPadding={0}
                            style={{
                                maxWidth: 150,
                                width: "100%",
                                marginLeft: "auto",
                                borderCollapse: "collapse",
                            }}>
                            <tbody>
                                {
                                    billingDetails?.map((data, index) => (
                                        <tr key={index}>
                                            <td style={{ color: "#19213D", fontSize: 10, padding: "3px 5px", fontWeight: data?.label === "Sub Total" ? 700 : 400 }}>{data?.label}</td>
                                            <td style={{ color: data?.label === "Sub Total" ? "#19213D" : "#5D6481", fontSize: 10, padding: "3px 5px", textAlign: "right", fontWeight: data?.label === "Sub Total" ? 700 : 400 }}>&#8377; {data?.value}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </>
            <div className="html2pdf__page-break"></div>
        </>
    );
};

export default PrintInvoice;
