import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { validateOTP } from "../api";
import useValidations from "../../../utils/hooks/useValidations";

const useForgotPassword = (emailRef) => {
  const { onlyNumbers } = useValidations();
  const { email } = useParams();
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState("");

  const [otpStatus, setOtpStatus] = useState("idle");
  const [otpInfo, setOtpInfo] = useState({});

  useEffect(() => {
    if (otpStatus === "success") {
      navigate(`/login/reset-password`);
    } else if (otpStatus === "failed") {
      setErrors(otpInfo?.otp);
    }
    // eslint-disable-next-line
  }, [otpStatus]);

  const handleBlur = () => {
    setErrors("");
  };

  const handleChange = (e) => {
    setErrors("");
    if (
      (onlyNumbers(e.target.value) && e.target.value.length <= 6) ||
      e.target.value.length === 0
    ) {
      setOtp(e.target.value);
    }
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (otp) {
      setOtpStatus("pending");

      validateOTP({ otp: parseInt(otp), email: email }).then((response) => {
        if (response?.data?.status_code === 200 && response) {
          setOtpStatus("success");
          localStorage.setItem('USER_ACCESS_TOKEN', response.data.data.token);
        } else if (response?.success === false) {
          setOtpInfo({
            otp: "Invalid OTP",
          });
          setOtpStatus("failed");
        } else setOtpStatus("An error occured");
      });
    } else {
      setErrors("*OTP is required");
    }
  };

  return {
    otp,
    errors,
    otpStatus,
    handleChange,
    handleSubmit,
    handleBlur,
    handleLoginClick,
  };
};

export default useForgotPassword;
