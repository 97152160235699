import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  paymentGatewayId: "", // New variable for franchise ID
  statusId: "", // New variable for active status
  startDate: "", // New variable for start date
  endDate: ""
};

// export const getOrderDataByID = createAsyncThunk(
//   "v1/admin/order/detail",
//   async (id, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.get(`v1/admin/order/detail?order_id=${id}`);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const getProductDataByID = createAsyncThunk(
//   "v1/admin/order/product-detail",
//   async (id, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.get(`v1/admin/order/product-detail?product_id=${id}`);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const createBranches = createAsyncThunk(
//   "/v1/branch/store",
//   async (data, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.post("/v1/branch/store", data);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const updateBranches = createAsyncThunk(
//   "/v1/branch/update",
//   async ({ id, data }, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.post(`/v1/branch/update/${id}`, data);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const deleteBranch = createAsyncThunk(
//   "/v1/branch/delete",
//   async (Id, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.post(`/v1/branch/delete/${Id}`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const getBasicData = createAsyncThunk(
  "/v1/admin/payment-transaction/basic-data",
  async (param,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/admin/payment-transaction/basic-data`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.paymentGatewayId = action.payload.paymentGateway;
      state.statusId = action.payload.statusId;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    resetExtraFilters: (state, action) => {
      state.paymentGatewayId = "";
      state.statusId = "";
      state.startDate = "";
      state.endDate = "";
    },
  },
  // extraReducers: (builder) => {
    
  //  builder.addCase(getOrderDataByID.pending, (state) => {
  //     state.isLoading = true;
  //     state.error = null;
  //   })
  //   .addCase(getOrderDataByID.fulfilled, (state, action) => {
  //     state.selectedItemsDetails = action.payload.data.data;
  //   })
  //   .addCase(getOrderDataByID.rejected, (state, action) => {
  //     state.isLoading = false;
  //     state.error = action.payload;
  //   })
  //   .addCase(getProductDataByID.pending, (state) => {
  //     state.isProductLoading = true;
  //     state.error = null;
  //   })
  //   .addCase(getProductDataByID.fulfilled, (state, action) => {
  //     state.productDetails = action.payload.data.data;
  //   })
  //   .addCase(getProductDataByID.rejected, (state, action) => {
  //     state.isProductLoading = false;
  //     state.error = action.payload;
  //   });
    
  // },
  
});

export const { 
  updateConfig,
  resetExtraFilters,
  setStoreFormValues,
 } = transactionsSlice.actions;

export default transactionsSlice.reducer;
