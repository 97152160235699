import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetRolesDataQuery } from "../../../store/queries/settings";
import {
  deleteItemFromTable,
  updateConfig,
} from "../../../store/slices/Settings/roleSlice";

import { toast } from "react-toastify";

const useRoles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rolesState = useSelector((state) => state.roles);

  const { data: roles = {}, isFetching, refetch } = useGetRolesDataQuery({});

  const [showModal, setShowModal] = useState(false);

  // const hasCreatePermission = useMemo(() => {
  //   let permission = roles?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_create")
  //   );
  //   return permission?.[0]?.can_create ?? 0;
  // }, [roles]);
  // const hasEditPermission = useMemo(() => {
  //   let permission = roles?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_edit")
  //   );
  //   return permission?.[0]?.can_edit ?? 0;
  // }, [roles]);
  // const hasViewPermission = useMemo(() => {
  //   let permission = roles?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_view")
  //   );
  //   return permission?.[0]?.can_view ?? 0;
  // }, [roles]);
  // const hasDeletePermission = useMemo(() => {
  //   let permission = roles?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_delete")
  //   );
  //   return permission?.[0]?.can_delete ?? 0;
  // }, [roles]);

  const handleSort = (label) => {
    if (rolesState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = rolesState.sortOrder === "asc" ? "asc" : "desc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCreateClick = () => {
    setShowModal(true);
  };

  const handleEdit = (item) => {
    navigate({
      pathname: "/settings/role/permission",
      search: `role=${item[0]}&update=true`,
    });
  };
  const handleDelete = async (ids) => {
    dispatch(deleteItemFromTable(ids[0]))
      .unwrap()
      .then((result) => {
        if (result?.data?.message === "Success") {
          toast.success("User Type deleted successfully");
        } else {
          toast.error(result?.data?.message);
        }
        if (result.data.data) {
          refetch();
        }
      })
      .catch((err) => {});
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  return {
    roles,
    rolesState,
    isFetching,
    showModal,
    // hasCreatePermission,
    // hasEditPermission,
    // hasDeletePermission,
    // hasViewPermission,
    handleCreateClick,
    handleDelete,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
  };
};

export default useRoles;
