import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useOffers = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Non Season Offers",
      link: "/offers/non-season-offers",
      icon: <span className="material-symbols-outlined x4"> approval_delegation </span>,
      active: checkIfActiveRoute("/offers/non-season-offers", true),
    },
    {
      title: "Season Based Offers",
      link: "/offers/season-based-offers",
      icon: <span className="material-symbols-outlined x4"> redeem </span>,
      active: checkIfActiveRoute("/offers/season-based-offers", true),
    }
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.submenus?.map((side_menu) => side_menu.name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    // drawerMenu: drawerMenuPermission,
    drawerMenu: drawerMenuPermission,
  };
};

export default useOffers;
