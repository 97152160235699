import React from "react";
import Style from "./billingDetails.module.scss";
import {
  MultiColumnTable,
  SimpleReport,
  ModalLayout,
  DataContainer,
  WarningBox,
} from "@wac-ui-dashboard/wac_component_library";
import useBillingDetails from "./useBillingDetails";
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer";

const BillingDetails = () => {
  const {
    profileData,
    billingDetails,
    occupationDetails,
    familyDetails,
    headerDetails,
    isFetching,
    showModal,
    activeProfile,
    contactDetails,
    showImageVerificationModal,
    showDocumentVerificationModal,
    verificationState,
    ImageVerifyStatus,
    IdVerifyStatus,
    hasUpdatePermission,
    hasVerifyPermission,
    hasUploadsViewPermission,
    viewId,
    imageData,
    setViewId,
    setImageData,
    closeIdModal,
    setShowDocumentVerificationModal,
    setShowImageVerificationModal,
    closeModal,
    closeDocumentModal,
    refetch,
    onProfileVerifyClick,
    onProfileIdVerifyClick,
    handleCloseModal,
    handleEduClick,
    handleAboutClick,
    handleJobClick,
    handleFamilyClick,
    handleContactClick,
  } = useBillingDetails();

  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          {/* <div
            className={`col-12 ${
              Style.overview_wrap_list
            } ${"light_primary_bg"}`}
          >
            <div className="row gx-2 gx-xxl-6">
              <div className={`col-12 ${Style.overview_wrap_list_item}`}>
                {ImageVerifyStatus?.length > 0 &&
                hasUploadsViewPermission === 1 &&
                profileData?.data?.assigned_profile === 1 ? (
                  <div className="pro-m-4 pro-mx-5">
                    <WarningBox
                      title={`${profileData?.data?.first_name} has updated ${
                        profileData?.data?.gender?.id === 1 ? "his" : "her"
                      } profile image`}
                      button={hasVerifyPermission === 1 ? "Verify" : ""}
                      handleClick={onProfileVerifyClick}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {IdVerifyStatus?.length > 0 &&
                hasUploadsViewPermission === 1 &&
                profileData?.data?.assigned_profile === 1 ? (
                  <div className="pro-m-4 pro-mx-5">
                    <WarningBox
                      title={`${profileData?.data?.first_name} has updated ${
                        profileData?.data?.gender?.id === 1 ? "his" : "her"
                      } id proof`}
                      button={hasVerifyPermission === 1 ? "Verify" : ""}
                      handleClick={onProfileIdVerifyClick}
                    />
                  </div>
                ) : (
                  <></>
                )}

                <div className="pro-m-4 pro-mx-5">
                  <DataContainer>
                    <SimpleReport data={headerDetails} />
                  </DataContainer>
                </div>
                <div className="pro-m-4 pro-mx-5">
                  <MultiColumnTable
                    title={"About"}
                    data={[
                      {
                        label: `${
                          profileData?.data?.about_me ?? "Not specified"
                        }`,
                        value: "",
                      },
                    ]}
                    editIcon={
                      hasUpdatePermission === 1 &&
                      profileData?.data?.assigned_profile === 1 ? (
                        <span className="material-symbols-outlined">
                          edit_square
                        </span>
                      ) : (
                        false
                      )
                    }
                    handleEdit={() => handleAboutClick()}
                    // extraClassNames={`$Style.multi_column_table`}
                    extraClassNames={`${Style.table_wrap}`}
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className={`col-12 ${Style.overview_wrap_list}`}>
            <div className="row gx-2 gx-xxl-6">
              <div className={`col-12 ${Style.overview_wrap_list_item}`}>
                <div className="pro-m-4 pro-mx-5">
                  <MultiColumnTable
                    minWidth={`30%`}
                    title={"Billing Details"}
                    data={billingDetails}
                    // editIcon={
                    //   hasUpdatePermission === 1 &&
                    //   profileData?.data?.assigned_profile === 1 ? (
                    //     <span className="material-symbols-outlined">
                    //       edit_square
                    //     </span>
                    //   ) : (
                    //     false
                    //   )
                    // }
                    // handleEdit={() => handleEduClick()}
                    extraClassNames={`${Style.table_wrap}`}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className={`col-12 ${Style.overview_wrap_list}`}>
            <div className="row gx-2 gx-xxl-6">
              <div className={`col-12 ${Style.overview_wrap_list_item}`}>
                <div className="pro-m-4 pro-mx-5">
                  <MultiColumnTable
                    minWidth={`30%`}
                    title={"Job Details"}
                    data={occupationDetails}
                    // extraClassNames={Style.multi_column_table}
                    editIcon={
                      hasUpdatePermission === 1 &&
                      profileData?.data?.assigned_profile === 1 ? (
                        <span className="material-symbols-outlined">
                          edit_square
                        </span>
                      ) : (
                        false
                      )
                    }
                    handleEdit={() => handleJobClick()}
                    extraClassNames={`${Style.table_wrap}`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`col-12 ${Style.overview_wrap_list}`}>
            <div className="row gx-2 gx-xxl-6">
              <div className={`col-12 ${Style.overview_wrap_list_item}`}>
                <div className="pro-m-4 pro-mx-5">
                  <MultiColumnTable
                    minWidth={`30%`}
                    title={"Family Details"}
                    data={familyDetails}
                    editIcon={
                      hasUpdatePermission === 1 &&
                      profileData?.data?.assigned_profile === 1 ? (
                        <span className="material-symbols-outlined">
                          edit_square
                        </span>
                      ) : (
                        false
                      )
                    }
                    handleEdit={() => handleFamilyClick()}
                    extraClassNames={`${Style.table_wrap}`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`col-12 ${Style.overview_wrap_list}`}>
            <div className="row gx-2 gx-xxl-6">
              <div className={`col-12 ${Style.overview_wrap_list_item}`}>
                <div className="pro-m-4 pro-mx-5">
                  <MultiColumnTable
                    minWidth={`30%`}
                    title={"Contact Details"}
                    data={contactDetails}
                    editIcon={
                      hasUpdatePermission === 1 &&
                      profileData?.data?.assigned_profile === 1 ? (
                        <span className="material-symbols-outlined">
                          edit_square
                        </span>
                      ) : (
                        false
                      )
                    }
                    handleEdit={() => handleContactClick()}
                    extraClassNames={`${Style.table_wrap}`}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}

      {/* <ModalLayout show={showModal} handleClose={handleCloseModal}>
        <AboutMyselfForm
          handleCancel={handleCloseModal}
          userId={activeProfile}
          isFetching={isFetching}
          profileData={profileData}
          data={profileData?.data?.about_me}
          refetch={refetch}
        />{" "}
      </ModalLayout>

      <ModalLayout show={viewId} handleClose={closeIdModal}>
        <IdImageCard data={imageData} handleClose={closeIdModal} />
      </ModalLayout>

      <ModalLayout show={showImageVerificationModal} handleClose={closeModal}>
        <ProfileCard
          data={verificationState}
          activeID={activeProfile}
          refetch={refetch}
          hasUploadViewPermission={hasUploadsViewPermission}
          setShowImageVerificationModal={setShowImageVerificationModal}
        />
      </ModalLayout>

      <ModalLayout
        show={showDocumentVerificationModal}
        handleClose={closeDocumentModal}
      >
        <DocumentCard
          setViewId={setViewId}
           hasUploadViewPermission={hasUploadsViewPermission}
          setImageData={setImageData}
          setShowDocumentVerificationModal={setShowDocumentVerificationModal}
          data={verificationState}
          activeID={activeProfile}
          refetch={refetch}
        />
      </ModalLayout> */}
    </div>
  );
};

export default BillingDetails;
