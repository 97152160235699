import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useConfirmation from "./useConfirmation";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import Style from "./editPolicy.module.scss";

const Confirmation = ({ closeModal, handleFunction, refetch }) => {
  const {
    formik,
    statusOptions
  } = useConfirmation({ closeModal, handleFunction, refetch });

  return (
    <div className="pro-p-5">
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Download Type
          </label>
          <Select
            name="type"
            options={statusOptions} // replace with your franchise options
            onChange={(selectedOption) =>
              {formik.setFieldValue("type", selectedOption)}
            }
            className={`pro-input lg ${
              formik.errors.type && formik.touched.type && "error"
            }`}
            classNamePrefix={`pro-input`}
            onBlur={formik.handleBlur}
            value={formik.values.type}
          />
          {formik.touched.type && formik.errors.type && (
            <div className="error">{formik.errors.type}</div>
          )}
        </div>
      </div>
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => closeModal()}
          type="button"
        >
          Cancel
        </button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;
