import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
  Button,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../Global/OrderColumn";
import useOrders from "./useOrders";
import EmptyData from "../Global/EmptyData";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import Style from "./orders.module.scss";
import CustomDateRangePicker from "../Global/CustomDateRangePicker"
import ViewDetails from "./printInvoice/viewDetails";
import Select from "react-select";
import Confirmation from "./Confirmation";
import ViewPickListDetails from "./printPickList/viewPickListDetails";
import PrintPickList from "./printPickList";

const Orders = ({ dashboard = false }) => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDownload,
    showDownloadConfirm,
    setShowDownloadConfirm,
    handleDownloadAction,
    handleClearClick,
    updateBrancheFields,
    showCreateModal,
    closeModal,
    handleEditAction,
    handleSelectedIds,
    handleDashboardRedirect,
    handleBulkDownLoad,
    handleActionChange,
    actionOptions,
    handleSyncEship,
    handleSyncShip,
    handleDateRangeChange,
    date,
    printData,
    pickListData,
    printRef,
    basicData,
    handleActionSetStatus,
    closeConfirmModal,
    handleFuction,
    showConfirm,
    setShowConfirm,
    handleSpecificAction,
    PdfLoading
  } = useOrders({ dashboard });


  return (
    <>
      <CommonLayout dashboard={true}>
        {!dashboard ? (
          <HeadingGroup
            title={"Orders"}
            className={`pro-mb-4`}
          // buttonTitle={"Add new"}
          // handleClick={handleCreateClick}
          />
        ) : (
          <></>
        )}
        <div className={`col-auto pro-pt-3 pro-pb-6`}>
          {!dashboard ? (
            <div className="row">
              <div className="col">
                <SearchFilters
                  data={mainData?.data?.filters}
                  // onDateChange={handleDateChange}
                  onSearchInput={handleSearch}
                  showActions={false}
                  handleActionClick={handleEditClick}
                  loading={isLoading}
                  SearchIcon={
                    <span className="material-symbols-outlined"> search </span>
                  }
                  actionOptions={actionOptions?.filter((item) => item.value === 0)}
                  onActionOptionChange={handleActionChange}
                  //showClearFilters
                  handleClear={handleClearClick}
                  searchInputProps={{ value: menuState?.search }}
                  dropDownFilter={
                    <>
                      <div className="col-auto">
                        <CustomDateRangePicker
                          defaultDateRange={date}
                          handleDateRangeChange={handleDateRangeChange}
                        />
                      </div>

                      <div className="col-auto dropdown-filter">
                  <Select
                    onChange={handleActionSetStatus}
                    options={basicData}
                    value={basicData?.filter(
                      (item) =>
                        item?.label === menuState?.selectedStatus?.label
                    )}
                    placeholder="Select Status"
                    classNamePrefix={`pro-input`}
                    className={`pro-input lg search-filter-select`}
                    isClearable={true}
                  />
                </div>
                      
                    </>
                  }
                />
                
              </div>
              <div className="col-auto pro-d-flex">
                <button 
                  className={`pro-btn pro-items-center pro-btn-primary ${false ? "loading" : ""}`}
                  type="submit"
                  onClick={handleSyncShip}>
                  <span className="material-symbols-outlined x4" > sync </span> 
                  <span>Sync to ShipRocket</span>
                </button>

                <button 
                  className={`pro-btn pro-items-center pro-btn-primary pro-ms-3 ${false ? "loading" : ""}`}
                  type="submit"
                  onClick={handleSyncEship}>
                   <span className="material-symbols-outlined x4"> sync_alt </span> 
                  <span>Sync to EShipz</span>
                </button>
              </div>
            </div>

          ) : (
            <></>
          )}
          
          {/* <ModalLayout show={true}>
            <PickList />
          </ModalLayout> */}
          {mainData?.data?.result?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              onClick={
                dashboard ? () => handleDashboardRedirect() : () => { }
              }
              className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
            >
              <Table
                data={mainData?.data?.result || []}
                uniqueID={"id"}
                editIcon={<span className="material-symbols-outlined">edit</span>}
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                multiSelect={true}
                showCheckBox={dashboard ? false : true}
                handleSelect={handleSelectedIds}
                deletable={false}
                editable={false}
                handleEdit={handleEditAction}
                clear={menuState.clearSelection}
                fields={mainData?.data?.fields}
                SortIcon={<FaSort />}
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={menuState?.currentPageSize}
                assignable={true}
                assignIcon={<span class="material-symbols-outlined">mail</span>}
                assignText={"Download"}
                handleAssign={handleDownload}
              />
            </div>
          )}

          {mainData?.data?.result?.length > 0 && (
            <Pagination
              currentPage={currentPage}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === menuState?.currentPageSize
              )}
              totalPageCount={mainData?.data?.last_page}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
          {!dashboard ? (
            <>
              {" "}
              <OffCanvasLayout
                show={showCreateModal}
                handleClose={closeModal}
                title={menuState?.is_edit ? "Update Order" : "Add Order"}
                closeIcon={<span className="material-symbols-outlined">close</span>}
                backdrop="static"
              >
                {/* <BranchForm
              refetch={refetch}
              closeModal={closeModal}
            /> */}
              </OffCanvasLayout>
              <ModalLayout show={showEditModal} handleClose={closeEditModal} backdrop="static">
                <div className="pro-m-5">
                  <OrderColumn
                    title={"Choose which columns you see"}
                    refetch={refetch}
                    tableFields={Object.keys(mainData?.data?.fields ?? {})
                      .reduce((filteredObj, property) => {
                        filteredObj[property] = mainData?.data?.fields[property];
                        return filteredObj;
                      }, {})}
                    moduleId={mainData?.data?.module_id}
                    updateData={updateBrancheFields}
                  />
                </div>
              </ModalLayout>

              <ModalLayout
                centered={false}
                show={showConfirm}
                handleClose={setShowConfirm}
                backdrop="static"
              >
                <div className="pro-m-5">
                  <ConfirmationBox
                    title={`Are you sure you want to Download?`}
                    isRight={true}
                    cancelText={`No`}
                    submitText={PdfLoading ? "Downloading.." : "Yes"}
                    cancelAction={setShowConfirm}
                    submitAction={handleSpecificAction}
                  >
                    ConfirmationBox
                  </ConfirmationBox>

                  {true && <span className="error-message">{true}</span>}
                </div>
              </ModalLayout>

              <ModalLayout
        centered={false}
        show={showDownloadConfirm}
        handleClose={() => closeConfirmModal()}
        title={"Select the type"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle ={{root: Style.modal_root}}
      >
        <Confirmation closeModal={() => closeConfirmModal()} handleFunction={handleFuction} refetch={refetch} />
      </ModalLayout>
            </>
          ) : (
            <></>
          )}
        </div>
      </CommonLayout>
      {printData && (
        <ViewDetails
          ref={printRef}
          invoiceData = {printData}
        />
      )}

{pickListData && (
        <ViewPickListDetails
          ref={printRef}
          invoiceData = {pickListData}
        />
      )}
      

    </>
  );
};

export default Orders;
