import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useSettings = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const savedBranchPermissions = JSON.parse(localStorage.getItem('dashboard_permissions')) || [];

  const drawerMenu = [
    {
      title: "Roles",
      link: "/settings/role",
      icon: <span className="material-symbols-outlined x4"> workspace_premium </span>,
      active: checkIfActiveRoute("/settings/role", true),
    },
    {
      title: "Shipping Charge",
      permission: "Shipping Charge",
      link: "/settings/shipping-charge",
      iconAsset: "DrawerIcon3",
      icon: (
        <span className="material-symbols-outlined x4">workspace_premium</span>
      ),
      active: checkIfActiveRoute("/settings/shipping-charge"),
    },
  
  ];
 
  const newDrawerMenuPermission = [
    {
      title: "Appearance",
      link: "/settings/appearance",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">receipt_long</span>,
      active: checkIfActiveRoute("/settings/appearance"),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = savedBranchPermissions?.flatMap?.((menu) => {
      return [
      // menu.name,
      ...menu.submenus?.map((side_menu) => side_menu.name),
    ]
  });
    let temp = drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
      return temp
    //eslint-disable-next-line
  }, [savedBranchPermissions, location.pathname]);

  const combinedMenuPermissions = [
    ...drawerMenuPermission,
    ...newDrawerMenuPermission,
  ];
  return {
    drawerMenu: combinedMenuPermissions,
  };
};

export default useSettings;
