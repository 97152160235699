import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAxiosInstance } from "../../../../api";
import {
	getMenuPermissions,
	updateConfig as updateGlobalConfig,
} from "../../../../store/slices/Global";
import {
	getAllPermissions,
	getPermission,
	updateConfig,
} from "../../../../store/slices/Settings/roleSlice";
import { settings } from "../../../../store/queries/settings";

const useManageRoles = () => {
	const [params] = useSearchParams();

	const role = params.get("role");
	const update = params.get("update");
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const state = useSelector((state) => state.role);

	const [name, setName] = useState("");
	// const [permissionModules, setPermissionModules] = useState({});
	const [error, setError] = useState("");

	const [selectedItems, setSelectedItems] = useState([]);


	useEffect(() => {
		dispatch(
			updateGlobalConfig((state) => {
				state.menuDrawer = {
					show: true,
					backTo: "/settings/role",
					text: "Back to role",
				};
			})
		);
		if (state.allPermissionsStatus === "idle") {
			dispatch(getAllPermissions());
		}
		if (role) {
			dispatch(getPermission({ id: role }))
				.unwrap()
				.then((r) => {
					if (update === "true") {
						setName(r.data.data.name);
					}
					let obj;

					const allPermissionIds = r.data.data.result.flatMap(item => item.permissions.map(permission => ({ id: permission.id })));
					setSelectedItems(allPermissionIds)

					// r.data.data.result.forEach((m) => {
					// 	obj = { ...obj, [m.id]: m.permissions };
					// });

					// setPermissionModules(obj);
				});
		}

		return () => {
			dispatch(
				updateGlobalConfig((state) => {
					state.menuDrawer = {};
				})
			);
		};
		// eslint-disable-next-line
	}, []);

	const handleNameChange = (e) => {
		setName(e.target.value);
		if (error) {
			setError("");
		}
	};


	const handlePermissionChange = (item, moduleId) => {
		const isSelected = selectedItems.some(items => items.id === item.id);
		// If it's selected, remove it; otherwise, add it to the array
		if (!item.e.target.checked) {
			const updatedItems = selectedItems.filter(items => items.id !== item.id);
			setSelectedItems(updatedItems);
		} else {
			const newItem = { id: item.id };
			setSelectedItems([...selectedItems, newItem]);
		}


	};

	const handleSave = async () => {
		if (name.length === 0) {
			setError("*Name cannot be empty");
		} else {
			const api = await getAxiosInstance();
			if (!update) {
				api
					.post("/v1/admin/permission/role/create", { name: name, permissions: selectedItems })
					.then((r) => {
						if (r.status === 200) {
							toast.success("Role created successfully", {
								position: "top-right",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "colored",
							});
							dispatch(settings.util.invalidateTags(["Roles"]));

							navigate("/settings/role");
						} else {
							toast.error("Failed to Create Role");
						}
					})
					.catch((err) => {
						// if (err.response.status === 400) {
						toast.error("Failed to Create Role");
						// }
					});
			} else {
				api
					.post(`/v1/admin/permission/role/update`, {
						// role_id: role,
						id: role,
						name: name,
						permissions: selectedItems
					})
					.then((r) => {
						if (r.status === 200) {
							toast.success("Role updated successfully", {
								position: "top-right",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "colored",
							});
							dispatch(
								updateConfig((state) => {
									state.allPermissionsStatus = "idle";
									state.allPermissions = [];
									state.rolePermission = {};
									state.rolePermissionStatus = "idle";
								})
							);
							dispatch(
								getMenuPermissions({
									token: localStorage.getItem("USER_ACCESS_TOKEN"),
								})
							);

							dispatch(settings.util.invalidateTags(["Roles"]));

							navigate("/settings/role");
						} else {
							toast.error("Failed to Update Role");
						}
					});
			}
		}
	};

	return {
		state,
		role,
		update,
		name,
		error,
		handleNameChange,
		handlePermissionChange,
		handleSave,
	};
};

export default useManageRoles;
