import {
  HeadingGroup,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import usePrivacyPolicy from "./usePrivacyPolicy";
import EditPolicy from "./EditPolicy";
import Style from './privacyPolicy.module.scss';

const PrivacyPolicy = () => {
  const { mainData, menuState, closeModal, refetch, handleEditClick } =
    usePrivacyPolicy();

  const parse = require("html-react-parser");
  const content = mainData?.data?.result?.description ?? "";

  return (
    <>

      <HeadingGroup title={"Privacy Policy"} extraClassName={`pro-mb-5`}>
        <button
          className="pro-btn pro-btn-primary pro-items-center"
          onClick={handleEditClick}
        >
          <span class="material-symbols-outlined">edit_square</span>
          <span>Edit</span>
        </button>
      </HeadingGroup>

      <div className={`pro-pb-5 ${Style.admin_content_area}`}>
        {parse(content)}
      </div >

      <ModalLayout
        centered={false}
        show={menuState.showEditModal}
        handleClose={() => closeModal()}
        title={menuState?.is_edit ? "Update Privacy Policy" : ""}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle ={{root: Style.modal_root}}
      >
        <EditPolicy closeModal={() => closeModal()} refetch={refetch} />
      </ModalLayout>
    </>
  );
};

export default PrivacyPolicy;
