import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useEditCompanyDetails from "./useEditCompanyDetails";
import { capitalizeInitial } from "../../../../utils/functions/table";

const EditCompanyDetails = ({ closeModal, refetch, isStickyFooter }) => {
  const {
    formik,
    selectedItemsDetails,
  } = useEditCompanyDetails({ closeModal, refetch });

  return (
    <div className="pro-p-5">
			<Input
				label={"Address Line 1*"}
				type="text"
				id="address
            "
				name="address
            "
				className={`pro-input lg ${formik.errors.address && formik.touched.address && "error"
					}`}
				{...formik.getFieldProps("address")}
				onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
				error={formik.errors.address && formik.touched.address}
				errorMessage={formik.errors.address}
			/>

<Input
				label={"Address Line 2*"}
				type="text"
				id="address_line_2
            "
				name="address_line_2
            "
				className={`pro-input lg ${formik.errors.address_line_2 && formik.touched.address_line_2 && "error"
					}`}
				{...formik.getFieldProps("address_line_2")}
				onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
				error={formik.errors.address_line_2 && formik.touched.address_line_2}
				errorMessage={formik.errors.address_line_2}
			/>
      
      <Input
				label={"Email*"}
				type="text"
				id="email
            "
				name="email
            "
				className={`pro-input lg ${formik.errors.email && formik.touched.email && "error"
					}`}
				{...formik.getFieldProps("email")}
				onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
				error={formik.errors.email && formik.touched.email}
				errorMessage={formik.errors.email}
			/>

<Input
				label={"Phone*"}
				type="text"
				id="phone
            "
				name="phone
            "
				className={`pro-input lg ${formik.errors.phone && formik.touched.phone && "error"
					}`}
				{...formik.getFieldProps("phone")}
				onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
				error={formik.errors.phone && formik.touched.phone}
				errorMessage={formik.errors.phone}
			/>

			<div
				className={`col-12 pro-d-flex pro-justify-end ${isStickyFooter && "offcanvas-footer-sticky-btns"
					}`}
			>
				<Button
					className={"pro-btn-link lg pro-px-5"}
					onClick={() => closeModal()}
				>
					Cancel
				</Button>

				<Button
					className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
					type="submit"
					// disabled={hasUpdatePermission === true ? false : true}
					onClick={formik.handleSubmit}
				>
					{selectedItemsDetails === "" ? "Create" : "Update"}
				</Button>
			</div>
		</div>
  );
};

export default EditCompanyDetails;
