import {  createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  activeFilter: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  clearSelection: false,
  queryData: {},
  scrollStatusPage:{},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  is_edit: false,
  streamData: {},
  userData: {},
  selectedItemsDetails: {},
  selectedItemData: {},
  showCreateModal: false,
  showNotesCk: false,
  viewNotes: false,
  showMailCk: false,
  initial:true
};



const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(getEnquiryScrollListData.pending, (state, action) => {
  //     state.queryStatus = "pending";
  //   });
  //   builder.addCase(getEnquiryScrollListData.fulfilled, (state, action) => {
  //     if (state.queryData?.data && !action.meta?.arg?.clearState) {
  //       if (action?.payload?.data?.data?.rows?.enquiries?.data) {
  //         state.queryData = {
  //           ...action?.payload?.data?.data?.rows?.enquiries,
  //           queryStatus: "fulfilled",
  //           data: [
  //             ...state.queryData?.data,
  //             ...action?.payload?.data?.data?.rows?.enquiries?.data,
  //           ],
  //         };
  //       } else {
  //         state.queryData = {
  //           ...action?.payload?.data?.data?.rows?.enquiries,
  //           queryStatus: "fulfilled",
  //           data: [...state.queryData?.data],
  //         };
  //       }
  //     } else {
  //       state.queryData = {
  //         ...action?.payload?.data?.data?.rows?.enquiries,
  //         queryStatus: "fulfilled",
  //         page: 1,
  //       };
  //     }
  //     state.queryStatus = "fulfilled";
  //   });
  //   builder.addCase(getEnquiryScrollListData.rejected, (state, action) => {
  //     state.queryStatus = "failed";
  //   });
  //   builder
  //     .addCase(getEnquiryDataByID.pending, (state) => {
  //       state.isLoading = true;
  //       state.error = null;
  //     })
  //     .addCase(getEnquiryDataByID.fulfilled, (state, action) => {
  //       state.selectedItemsDetails = action.payload.data.data;
  //     })
  //     .addCase(getEnquiryDataByID.rejected, (state, action) => {
  //       state.isLoading = false;
  //       state.error = action.payload;
  //     });
  //   builder
  //     .addCase(getStreams.pending, (state) => {
  //       state.isLoading = true;
  //       state.error = null;
  //     })
  //     .addCase(getStreams.fulfilled, (state, action) => {
  //       state.streamData = action.payload.data.data;
  //     })
  //     .addCase(getStreams.rejected, (state, action) => {
  //       state.isLoading = false;
  //       state.error = action.payload;
  //     });
  //   builder
  //     .addCase(getUsers.pending, (state) => {
  //       state.isLoading = true;
  //       state.error = null;
  //     })
  //     .addCase(getUsers.fulfilled, (state, action) => {
  //       state.userData = action.payload.data.data;
  //     })
  //     .addCase(getUsers.rejected, (state, action) => {
  //       state.isLoading = false;
  //       state.error = action.payload;
  //     });
  // },
});

export const { updateConfig } = enquirySlice.actions;

export default enquirySlice.reducer;
