import { useFormik, yupToFormErrors } from "formik"
import * as Yup from 'yup'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify'
import {
  updateConfig,
  updateNonSeasonBasedOffer,
} from "../../../../store/slices/NonSeasonOffers/nonSeasonOffersSlice.js";
import { useGetFrameDataQuery, useGetSizeDataQuery } from "../../../../store/queries/nonSeasonalOffers/index.js"


const useSeasonBasedOfferForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);
  const { is_edit, selectedId, selectedItemsDetails } = useSelector((state) => state.nonSeasonOffers);
  const { data: SizeData = {}, isSuccess: isOptionDatas } = useGetSizeDataQuery({});
  const { data: FrameData = {}, isSuccess: isFrameDatas } = useGetFrameDataQuery({});

  const [is_offer_Type, setOfferType] = useState(selectedItemsDetails !== "" ? selectedItemsDetails?.offer_type.toString() : "");
  const [is_applicable, setApplicable] = useState(selectedItemsDetails !== "" ? selectedItemsDetails?.applicable_to.toString() : "");

  const Options = [
    {
      name: "Amount",
      id: "0"
    },
    {
      name: "Percentage",
      id: "1",
    },
    {
      name: "Combo",
      id: "2"
    }
  ]

  const ApplicableOptions = [
    {
      name: "All Products",
      id: "0"
    },
    {
      name: "Poster Size Frames",
      id: "2",
    },
  ]

  const validation = Yup.object({
    code: Yup.string()
      .min(2, "The code must be at least 2 characters")
      .required("*Required"),
    title: Yup.string()
      .min(2, "The title must be at least 2 characters")
      .required("*Required"),
    description: Yup.string()
      .min(2, "The description must be at least 2 characters")
      .required("*Required"),
    offer_type: Yup.string().required("*Required"),
    percentage: is_offer_Type === "1" ? Yup.number().required("*Required") : "",
    amount: is_offer_Type === "0" ? Yup.number().required("*Required") : "",
    buy_count: is_offer_Type === "2" ? Yup.number().required("*Required") : "",
    off_count: is_offer_Type === "2" ? Yup.number().required("*Required") : "",
    size_type: (is_offer_Type === "2" || (is_offer_Type === "1" && is_applicable === "2")) ? Yup.number().required("*Required") : "",
    frame_type: is_offer_Type === "0" ? Yup.number().required("*Required") : "",

  });


  const formik = useFormik({
    initialValues: {
      id: selectedItemsDetails !== ""
        ? selectedItemsDetails.id
        : "",
      code: selectedItemsDetails !== ""
        ? selectedItemsDetails.code
        : "",
      title: selectedItemsDetails !== ""
        ? selectedItemsDetails.title
        : "",
      description: selectedItemsDetails !== ""
        ? selectedItemsDetails.description
        : "",
      offer_type: selectedItemsDetails !== ""
        ? selectedItemsDetails?.offer_type.toString()
        : "",
      status:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.status !== null
            ? selectedItemsDetails?.status
            : 0
          : 0,
      percentage: selectedItemsDetails !== ""
        ? selectedItemsDetails?.offer_type.toString() == "1"
          ? selectedItemsDetails?.percentage
          : ""
        : "",
      amount: selectedItemsDetails !== ""
        ? selectedItemsDetails?.offer_type.toString() == "0"
          ? selectedItemsDetails?.amount
          : ""
        : "",
      buy_count: selectedItemsDetails !== ""
        ? selectedItemsDetails?.offer_type.toString() == "2"
          ? selectedItemsDetails?.buy_count
          : ""
        : "",
      off_count: selectedItemsDetails !== ""
        ? selectedItemsDetails?.offer_type.toString() == "2"
          ? selectedItemsDetails?.off_count
          : ""
        : "",
      frame_type: selectedItemsDetails !== ""
        ? selectedItemsDetails?.offer_type.toString() == "0"
          ? selectedItemsDetails?.applicable_id
          : null
        : null,
      applicable_to: selectedItemsDetails !== ""
        ? selectedItemsDetails?.applicable_to.toString()
        : "",
      size_type: (selectedItemsDetails !== "" &&
        ((selectedItemsDetails?.offer_type.toString() === "2" &&
          selectedItemsDetails?.applicable_id) ||
          (selectedItemsDetails?.applicable_to.toString() === "2" &&
            selectedItemsDetails?.offer_type.toString() === "1")))
        ? selectedItemsDetails?.applicable_id
        : null,

    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {

      let obj = {
        id: is_edit ? selectedId : "",
        code: values.code,
        title: values.title ? values.title : "",
        description: values.description ? values.description : "",
        offer_type: values.offer_type,
        status: +values.status,
        is_seasonal: 0,
      };


      switch (values.offer_type) {
        case "2":
          obj.applicable_to = "2";
          obj.applicable_id = values?.size_type;
          obj.buy_count = values?.buy_count;
          obj.off_count = values?.off_count;
          break;

        case "0":
          obj.applicable_to = "1";
          obj.applicable_id = values?.frame_type;
          break;

        case "1":
          if (values?.size_type) {
            obj.applicable_to = "2";
            obj.applicable_id = values?.size_type;
          } else {
            obj.applicable_to = "0";
          }
          break;
      }

      switch (values.offer_type) {
        case "1":
          obj.percentage = values?.percentage;
          break;

        default:
          obj.amount = values?.amount;
          break;
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      dispatch(
        updateNonSeasonBasedOffer({ data: formData })
      ).then((response) => {
        if (response?.payload?.status_code === 200) {
          resetForm();
          closeModal?.();
          refetch();
          dispatch(
            updateConfig((state) => {
              state.showCreateModal = false;
            })
          );
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );

          toast.success(response?.payload?.message);
        } else if (response?.payload?.status_code === 400) {
          formik.setErrors(response?.payload?.message);
        } else toast.error(response?.payload?.message);
      });
    },

  })

  const setApplicableTo = (id) => {
    setApplicable(id);
    if (id === "0" && formik.values?.size_type) {
      formik.setFieldValue("size_type", null);
      formik.setFieldValue("applicable_id", null)
    }
  }


  return {
    selectedItemsDetails,
    profilefileInputRef,
    formik,
    Options,
    SizeData,
    ApplicableOptions,
    FrameData,
    setApplicableTo,
    setOfferType,
    setApplicable
  }
}

export default useSeasonBasedOfferForm
