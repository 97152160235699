import React from "react";
// import useViewProfileDetails from "./useViewProfileDetails";
// import ProfileData from "./ProfileData";
import PrintInvoice from ".";
// import Style from "./viewProfileDetails.module.scss";
import Style from "./PrintInvoice.module.scss";

const ViewDetails = React.forwardRef((props, ref) => {
  return (
    
    <div className={`${Style.pdf_container}`} >
      <div ref={ref} style={{ maxWidth: 595, width: "100%", fontSize: 8, margin: "0px auto", padding: 40 }} id="element-to-print">
        {props?.invoiceData && props?.invoiceData?.result.map((item, index) => (
          <PrintInvoice
            key={index}
            index={index}
            item={item}
            // Add other props as needed
          />
        ))}
      </div>
      </div>
    
    // <></>
  
  );
});

export default ViewDetails;
