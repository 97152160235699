import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
	updateConfig,
	createNewEntry,
} from "../../../../store/slices/Testimonials/testimonialsSlice.js";

const useTestimonialsForm = ({ refetch, closeModal }) => {
	const dispatch = useDispatch();

	const profilefileInputRef = useRef(null);

	const { is_edit, selectedId, selectedItemsDetails } = useSelector((state) => state.testimonials);

	const [imageCoverPreview, setImageCoverPreview] = useState(
		selectedItemsDetails !== "" ? selectedItemsDetails?.image_url : ""
	);

	const validation = Yup.object({
		name: Yup.string()
			.min(2, "The name must be at least 2 characters")
			.max(20, "max 20 characters allowed")
			.required("*Required"),
		description: Yup.string()
			.min(2, "The description must be at least 2 characters")
			.required("*Required"),
		rate: Yup.number()
			.min(0, 'Rate must be greater than or equal to 0')
			.max(5,'Rate must be less than or equal to 5')
			.required("*Required"),
		image: is_edit ? Yup.string() : Yup.string().required('*Required'),

	});

	const formik = useFormik({
		initialValues: {
			name:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.name
					: "",
			rate:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.rate
					: "",
			description:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.description
					: "",
			status:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.status !== null
						? selectedItemsDetails?.status
						: 0
					: 0,
			image: "",

		},

		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, { resetForm }) => {
			let obj = {
				name: values.name,
				description: values.description ? values.description : "",
				status: +values.status,
				rate: values?.rate ? values?.rate : "",
			};

			if (is_edit) {
				obj.id = selectedId;
			}

			if (values.image) {
				obj.image = values.image;
			}

			let formData = new FormData();

			Object.keys(obj).forEach((key) => {
				return formData.append(key, obj[key]);
			});

			dispatch(createNewEntry(formData)).then((response) => {
				if (response?.payload?.status_code === 200) {
					resetForm();
					refetch();
					closeModal?.();
					dispatch(
						updateConfig((state) => {
							state.showCreateModal = false;
						})
					);
					if (is_edit) {
						dispatch(
							updateConfig((state) => {
								state.clearSelection = true;
							})
						);
					}

					toast.success(response?.payload?.message);
				} else if (response?.payload?.status_code === 400) {
					formik.setErrors(response?.payload?.message);
				} else toast.error(response?.payload?.message);
			});
		},
	});

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showCreateModal = false;
			})
		);
	};


	const handleCoverImage = (e) => {
		const files = e.target.files;
	  
		if (files?.[0]?.size / (1024 * 1024) <= 5) {
		  const allowedExtensions = ["jpeg", "jpg", "png"];
		  const file = e.target.files[0];
		  const fileExtension = file.name.split('.').pop().toLowerCase();
	  
		  if (allowedExtensions.includes(fileExtension)) {
			formik.setFieldValue("image", e?.target?.files?.[0]);
	  
			const reader = new FileReader();
	  
			reader.onloadend = () => {
			  setImageCoverPreview(reader.result);
			};
	  
			if (file) {
			  reader.readAsDataURL(file);
			}
		  } else {
			formik.setFieldError("image", "The image must be a .jpeg, .jpg, or .png file.");
		  }
		} else if (files?.[0]?.size / (1024 * 1024) > 5) {
		  formik.setFieldError("image", "The image must be less than 5MB in size.");
		}
	  };



	return {
		formik,
		selectedItemsDetails,
		profilefileInputRef,
		handleCloseModal,
		imageCoverPreview,
		handleCoverImage
	};
};

export default useTestimonialsForm;
