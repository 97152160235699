import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] !== false) {
        if(params?.[key] !== undefined) {
          return `${key}=${params[key]}&`;
        } 
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("sort_by")}&sort_order=${
          params?.sort_order || "desc"
        }&per_page=${params?.per_page || "10"}&${getParams("search")}page=${
          params?.page || 1
        }&${getParams(
          "from"
        )}${getParams("to")}${getParams("sort_by_status")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const orders = createApi({
  reducerPath: "ordersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["orders"],
  endpoints: (builder) => ({
    getListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/order/list`,
      }),
      providesTags: ["orders"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/admin/table-permission-create`,
      }),

      invalidatesTags: ["orders"],
    }),
    updateStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin/order/change-status`,
      }),

      invalidatesTags: ["orders"],
    }),
    getOrderDetailData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/order/detail?order_id=${values.order_id}`,
      }),
      providesTags: ["orders"],
    }),
    getProductDetailData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/order/product-detail?product_id=${values.product_id}`,
      }),
      providesTags: ["orders"],
    }),

    updateHoldStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin/order/hold/change-status`,
      }),

      invalidatesTags: ["orders"],
    }),
  }),
});

export const { useGetListDataQuery, useUpdateStatusMutation, useUpdateHoldStatusMutation, useUpdateTableFieldsDataMutation, useGetOrderDetailDataQuery, useGetProductDetailDataQuery } = orders;
