
import {
  Button,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import useNonSeasonBasedOfferForm from "./useNonSeasonBasedOfferForm";
import {
  capitalizeOnSpace,
} from "../../../../utils/functions/table";
import Select from 'react-select'


const NonSeasonBasedOfferForm = ({
  refetch,
  closeModal,
  isStickyFooter,
}) => {

  const {
    selectedItemsDetails,
    profilefileInputRef,
    formik,
    Options,
    SizeData,
    ApplicableOptions,
    FrameData,
    setApplicableTo,
    setOfferType,
  } = useNonSeasonBasedOfferForm({ refetch, closeModal });
  return (
    <>
      <div className={`pro-w-100`}>
        <Input
          label={"Code*"}
          type="text"
          id="code
          "
          name="code
          "
          className={`pro-input lg ${formik.errors.code && formik.touched.code && "error"
            }`}
          {...formik.getFieldProps("code")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={formik.errors.code && formik.touched.code}
          errorMessage={formik.errors.code}
        />

        <Input
          label={"Title*"}
          type="text"
          id="title
          "
          name="title
          "
          className={`pro-input lg ${formik.errors.title && formik.touched.title && "error"
            }`}
          {...formik.getFieldProps("title")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={formik.errors.title && formik.touched.title}
          errorMessage={formik.errors.title}
        />

        <Input
          label={"Description*"}
          type="text"
          id="description
          "
          name="description
          "
          className={`pro-input lg ${formik.errors.description && formik.touched.description && "error"
            }`}
          {...formik.getFieldProps("description")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={formik.errors.description && formik.touched.description}
          errorMessage={formik.errors.description}
        />

        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Offer Type*
        </label>
        <Select
          id="offer_type"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${formik.touched.offer_type && formik.errors.offer_type && " error"
            }`}
          classNamePrefix="pro-input"
          options={Options}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={Options?.filter(
            (m) => formik.values.offer_type === m?.id
          )}
          onBlur={formik.handleBlur("offer_type")}
          onChange={(value) => {
            formik.setFieldValue("offer_type", value?.id || null);
            setOfferType(value?.id);
          }}
        />
        {formik.touched.offer_type && formik.errors.offer_type && (
          <span className="error-text">{formik.errors.offer_type}</span>
        )}{" "}

        {
          formik.values.offer_type && (
            formik.values.offer_type === "0" ?
              (<Input
                label={"Amount*"}
                type="number"
                id="amount
          "
                name="amount
          "
                className={`pro-input lg ${formik.errors.amount && formik.touched.amount && "error"
                  }`}
                {...formik.getFieldProps("amount")}
                onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
                error={formik.errors.amount && formik.touched.amount}
                errorMessage={formik.errors.amount}
              />)
              : formik.values.offer_type === "1" && (
                <Input
                  label={"Percentage*"}
                  type="number"
                  id="percentage
          "
                  name="percentage
          "
                  className={`pro-input lg ${formik.errors.percentage && formik.touched.percentage && "error"
                    }`}
                  {...formik.getFieldProps("percentage")}
                  onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
                  error={formik.errors.percentage && formik.touched.percentage}
                  errorMessage={formik.errors.percentage}
                />
              )
          )
        }

        {formik?.values?.offer_type === "0" && (
          <>

            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Frame Type*
            </label>

            <Select
              id="frame_type"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg ${formik.touched.frame_type && formik.errors.frame_type && "error"}`}
              classNamePrefix="pro-input"
              options={FrameData?.data}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={FrameData?.data?.filter((m) => formik.values.frame_type === m?.id)}
              onBlur={formik.handleBlur("frame_type")}
              onChange={(value) => {
                formik.setFieldValue("frame_type", value?.id || null);
              }}
            />

            {formik.touched.frame_type && formik.errors.frame_type && (
              <span className="error-text">{formik.errors.frame_type}</span>
            )}
          </>
        )}

        {formik?.values?.offer_type === "1" && (
          <>

            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Applicable To*
            </label>

            <Select
              id="applicable_to"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg ${formik.touched.applicable_to && formik.errors.applicable_to && "error"}`}
              classNamePrefix="pro-input"
              options={ApplicableOptions}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={ApplicableOptions?.filter((m) => formik.values.applicable_to === m?.id)}
              onBlur={formik.handleBlur("applicable_to")}
              onChange={(value) => {
                formik.setFieldValue("applicable_to", value?.id || null);
                setApplicableTo(value?.id);
              }}
            />

            {formik.touched.applicable_to && formik.errors.applicable_to && (
              <span className="error-text">{formik.errors.applicable_to}</span>
            )}

            {formik?.values?.applicable_to === "2" && (
              <>
                <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                  Size Type*
                </label>

                <Select
                  id="size_type"
                  isClearable={false}
                  placeholder={"Select"}
                  className={`pro-input lg ${formik.touched.size_type && formik.errors.size_type && "error"}`}
                  classNamePrefix="pro-input"
                  options={SizeData?.data}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) => option?.name}
                  value={SizeData?.data?.filter((m) => formik.values.size_type === m?.id)}
                  onBlur={formik.handleBlur("size_type")}
                  onChange={(value) => {
                    formik.setFieldValue("size_type", value?.id || null);
                  }}
                />

                {formik.touched.size_type && formik.errors.size_type && (
                  <span className="error-text">{formik.errors.size_type}</span>
                )}
              </>
            )}
          </>
        )}

        {formik?.values?.offer_type === "2" && (
          <>
            <Input
              label={"Buy Count*"}
              type="number"
              id="buy_count"
              name="buy_count"
              className={`pro-input lg ${formik.errors.buy_count && formik.touched.buy_count && "error"}`}
              {...formik.getFieldProps("buy_count")}
              onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
              error={formik.errors.buy_count && formik.touched.buy_count}
              errorMessage={formik.errors.buy_count}
            />

            <Input
              label={"Off Count*"}
              type="number"
              id="off_count"
              name="off_count"
              className={`pro-input lg ${formik.errors.off_count && formik.touched.off_count && "error"}`}
              {...formik.getFieldProps("off_count")}
              onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
              error={formik.errors.off_count && formik.touched.off_count}
              errorMessage={formik.errors.off_count}
            />

            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Size Type*
            </label>

            <Select
              id="size_type"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg ${formik.touched.size_type && formik.errors.size_type && "error"}`}
              classNamePrefix="pro-input"
              options={SizeData?.data}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={SizeData?.data?.filter((m) => formik.values.size_type === m?.id)}
              onBlur={formik.handleBlur("size_type")}
              onChange={(value) => {
                formik.setFieldValue("size_type", value?.id || null);
              }}
            />

            {formik.touched.size_type && formik.errors.size_type && (
              <span className="error-text">{formik.errors.size_type}</span>
            )}
          </>
        )}

<div className="pro-toggle">
          <div className="pro-toggle-box">
            <input
              id="status"
              name="status"
              type="checkbox"
              checked={
                Boolean(formik.values?.status) ?? false
              }
              onChange={(e) => {
                formik.setFieldValue(
                  "status",
                  !Boolean(formik.values?.status) ?? 0);
              }}
            />
            <span></span>
          </div>
          
        </div>

        <div
          className={`col-12 pro-d-flex pro-justify-end ${isStickyFooter && "offcanvas-footer-sticky-btns"
            }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {selectedItemsDetails === "" ? "Create" : "Update"}
          </Button>
        </div>
      </div>
    </>
  )
}

export default NonSeasonBasedOfferForm
