import { useFormik } from "formik";
import * as Yup from "yup";
import { useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { emailRegExp, phoneRegExp } from "../../../../utils/functions/table";
import { updateConfig } from "../../../../store/slices/Global";
import countryData from "../../../../utils/components/countryCode";
import { useGetProfileDataQuery, useUpdateProfileDataMutation, useUpdatePasswordMutation } from "../../../../store/queries/profile";
import useValidations from "../../../../utils/hooks/useValidations";
import { updateProfileData } from "../../../../store/slices/Profile/profileSlice";
import { resetPassword } from "../../../Auth/api";

const useProfileEdit = ({ setProfileViewVisible }) => {
  const { validPasswords } = useValidations();
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState("");
  const profilefileInputRef = useRef(null);
  // const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  // const [imagePreview, setImagePreview] = useState(
  //   userData !== "" ? userData?.image_url : ""
  // );

  const {
    data: userData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetProfileDataQuery({});

  // const cuntryCodeOptions = useMemo(
	// 	() =>
	// 		countryData?.map((opt) => {
	// 			return {
	// 				value: opt.value,
	// 				label: opt.label,
	// 				title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
	// 				code: opt.code,
	// 			};
	// 		}),
	// 	[]
	// );

  // const [updateProfileData] = useUpdateProfileDataMutation();
  // const [passwordReset] = useUpdatePasswordMutation();

  const [showExistingPassword, setShowExistingPassword] = useState(true);

  const imageFileInputRef = useRef(null);

  const dispatch = useDispatch();

  var validationSchemaProfileEdit = Yup.object({
    full_name:  Yup.string()
    .required("*Required"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
      
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(10, "Phone number must be 10 digits")
      .max(10, "Phone number must be 10 digits")
      .required("*Required"),

  });

  var validationSchemaPasswordChange = Yup.object({
    confirm_password: Yup.string().required("Current password is required"),
    password: Yup.string().required("New password is required"),
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const formikEditUserData = useFormik({
    initialValues: {
      full_name: userData?.data?.full_name,
      email: userData?.data?.email,
      // dial_code:userData?.data?.result?.dial_code,
      phoneNumber: userData?.data?.mobile,
    },

    validationSchema: validationSchemaProfileEdit,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const formikEditPassword = useFormik({
    initialValues: {
      // current_password: "",
      password: "",
      confirm_password: ""
    },

    validate: (values) => {
      let errors = {};
      const {
        uppercasePassword,
        lowercasePassword,
        digitsPassword,
        minLengthPassword,
        charsPassword,
      } = validPasswords(values.password);
      if (!values.password) {
        errors.password = "*Password required";
      }
      if (!values.confirm_password) {
        errors.confirm_password = "*Password confirmation required";
      }
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "*Passwords does not match";
      }
      if (!minLengthPassword) {
        errors.password = "*Password should be atleast 8 digits";
      }
      if (!charsPassword) {
        errors.password = "*Enter atleast 1 special character";
      }
      if (!digitsPassword) {
        errors.password = "*Enter atleast 1 digit";
      }
      if (!lowercasePassword) {
        errors.password = "*Enter atleast 1 lowercase letter";
      }

      if (!uppercasePassword) {
        errors.password = "*Enter atleast 1 uppercase letter";
      }

      return errors;
    },

    validationSchema: validationSchemaPasswordChange,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleSubmitPasswordChange(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      let obj = {
        // first_name: userData.first_name,
        // last_name: userData.last_name ? userData.last_name : "",
        id: 1,
        full_name: values.full_name,
        mobile: values.phoneNumber,
        country_code: userData?.data?.country_code,
        email: values.email,
      };

      // if (userData.profile?.name) {
      //   obj.image = userData.profile;
      // }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      dispatch(updateProfileData(formData))
        .unwrap()
        .then((data) => {
          if (data?.status_code === 200) {
            dispatch(
              updateConfig((state) => {
                state.isProfileViewVisible = false;
              })
            );
            refetch();
            resetForm();
            toast.success("Your Profile has been updated successfully.");
          }
        })
        .catch((err) => {
          toast.error("Failed to Update");
          // if (err?.message?.groom_name?.[0] !== "") {
          //   setFieldError("groomName", err?.message?.groom_name?.[0]);
          // }
        });
    } catch (error) {}
  };

  const handleSubmitPasswordChange = async (
    values,
    resetForm,
    setFieldError
  ) => {
    try {
      try {
        let obj = {
          password_confirmation: values.confirm_password,
          password: values.password,
        };

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        resetPassword(formData).then((data) => {
            if (data?.data?.status_code === 200) {
              dispatch(
                updateConfig((state) => {
                  state.isProfileViewVisible = false;
                })
              );
              resetForm();
              toast.success("Your password has been updated successfully.");
            }
          })
          .catch((err) => {
            if (err?.message?.password?.[0] !== "") {
              setFieldError("password", err?.message?.password?.[0]);
            }
          });
      } catch (error) {}
    } catch (error) {
      toast.success("Failed to Update!");
    }
  };

  const handleShowExistingPassword = (e) => {
    e.preventDefault();
    setShowExistingPassword(!showExistingPassword);
    var x = document.getElementById("current_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowNewPassword = (e) => {
    e.preventDefault();
    setShowNewPassword(!showNewPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  // const handleProfileImage = (e) => {
  //   const files = e.target.files;

  //   if (files?.[0]?.size / (1024 * 1024) <= 5) {
  //     formikEditUserData.setFieldValue("profile", e?.target?.files?.[0]);

  //     const file = e.target.files[0];
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //     };

  //     if (file) {
  //       reader.readAsDataURL(file);
  //       formikEditUserData.handleSubmit();
  //     }
  //   } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      
  //   }
  // };

  const handlePhoneNumberChange =()=>{

  }

  return {
    message,
    setLoading,
    setMessage,
    setOptions,
    // cuntryCodeOptions,
    // imagePreview,
    profilefileInputRef,
    formikEditUserData,
    formikEditPassword,
    loading,
    showExistingPassword,
    showNewPassword,
    handlePhoneNumberChange,
    // handleProfileImage,
    handleShowExistingPassword,
    handleShowNewPassword,
    handleShowConfirmPassword,
    imageFileInputRef,
    selectedOption,
    setSelectedOption,
    options,
    isDataLoading,
    setIsDataLoading,
    userData,
    showConfirmPassword,
    handleSubmitPasswordChange
  };
};

export default useProfileEdit;
