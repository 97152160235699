import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useEditVideo from "./useEditVideo";
import {
	capitalizeInitial,
} from "../../../../utils/functions/table";

const EditVideo = ({ closeModal, refetch, isStickyFooter }) => {
  const {
    formik,
    selectedItemsDetails,
  } = useEditVideo({ closeModal, refetch });

  return (
    <div className="pro-p-5">
			<Input
				label={"Video Url*"}
				type="text"
				id="video_url
            "
				name="video_url
            "
				className={`pro-input lg ${formik.errors.video_url && formik.touched.video_url && "error"
					}`}
				{...formik.getFieldProps("video_url")}
				onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
				error={formik.errors.video_url && formik.touched.video_url}
				errorMessage={formik.errors.video_url}
			/>
			{/* toggle button start  */}
			<div className="pro-toggle">
				<div className="pro-toggle-box">
					<input
						id="status"
						name="status"
						type="checkbox"
						checked={Boolean(formik.values?.status) ?? false}
						onChange={(e) => { formik.setFieldValue("status", !Boolean(formik.values?.status) ?? 0); }}
					/>
					<span></span>
				</div>
				
			</div>
			{/* toggle button end  */}

			<div
				className={`col-12 pro-d-flex pro-justify-end ${isStickyFooter && "offcanvas-footer-sticky-btns"
					}`}
			>
				<Button
					className={"pro-btn-link lg pro-px-5"}
					onClick={() => closeModal()}
				>
					Cancel
				</Button>

				<Button
					className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
					type="submit"
					// disabled={hasUpdatePermission === true ? false : true}
					onClick={formik.handleSubmit}
				>
					{selectedItemsDetails === "" ? "Create" : "Update"}
				</Button>
			</div>
		</div>
  );
};

export default EditVideo;
