
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import {
  updateConfig,
  getSeasonalDataOffersById,
   deleteSeasonBasedOffer
} from "../../../store/slices/SeasonBasedOffers/seasonBasedOffersSlice";
import {
  useGetSeasonBasedOffersQuery,
  useUpdateTableFeildsDataMutation,
  useUpdateStatusByIdMutation
} from "../../../store/queries/seasonBasedOffers";
import { addDays } from "date-fns";
import { getFormatedDate } from "../../../utils/functions/table";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { toast } from "react-toastify";
import { IconText } from "@wac-ui-dashboard/wac_component_library";


const useSeasonBasedOffers = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.seasonBasedOffers);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const { showCreateModal, selctedId } = useSelector((state) => state.seasonBasedOffers);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const [skip, setSkip] = useState(true);


  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetSeasonBasedOffersQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState.currentPage,
    is_seasonal: 1
  });

  const [updateTableFeildsData] = useUpdateTableFeildsDataMutation();
  const [updateStatus] = useUpdateStatusByIdMutation();

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);


  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = true;
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);

  };

  const handleEditAction = (data) => {
    setSkip(() => false);
    dispatch(getSeasonalDataOffersById(data?.[0]))
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      image: (feild, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image_url)}
            />
          </div>
        );
      },
      status: (field, data) => {
        const isChecked = data?.status;

        return (
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={isChecked}
                onChange={() => toggleStatusById(!(data?.status), data?.id)}
              />
              <span></span>
            </div>
            
          </div>
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const toggleStatusById = (status, id) => {
    let data = { "id": id, "status": status === true ? 1 : 0 }
    updateStatus(data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false; 
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteSeasonBasedOffer(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.message === "Success") {
            toast.success(result?.message);

          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };


  return {
    mainData,
    menuState,
    handleDateChange,
    handleSearch,
    handleEditClick,
    isLoading,
    handleClearClick,
    handleDelete,
    handleEditAction,
    handleSort,
    getRow,
    isFetching,
    currentPage: menuState.currentPage,
    paginationOptions,
    handlePagination,
    handlePageSize,
    showCreateModal,
    closeModal,
    showEditModal,
    closeEditModal,
    refetch,
    updateTableFeildsData,
    showImageModal,
    closeImageModal,
    imageData,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleCreateClick,
    actionOptions,
    handleActionChange
  }
}

export default useSeasonBasedOffers

