import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  updateConfig ,
  updateData
} from "../../../../store/slices/HomeVideos/homeVideosSlice.js";

const useEditVideo = ({ closeModal, refetch }) => {
  

  const { selectedItemsDetails, is_edit } = useSelector((state) => state.homeVideo);
  const dispatch = useDispatch();

  const validation = Yup.object({
		video_url: Yup.string() 
		.matches(    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|dailymotion\.com|yourvideowebsite\.com|commondatastorage\.googleapis\.com\/gtv-videos-bucket\/sample\/[a-zA-Z0-9.-]+\.mp4)(\/[^\s]*)?$/i,    'Invalid video URL'  )
		.required("*Required"),
	
	  });

	const formik = useFormik({
		initialValues: {
			id: 1,
			video_url:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.video_url !== null
						? selectedItemsDetails?.video_url
						: ""
					: "",
			status:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.status !== null
						? selectedItemsDetails?.status
						: 0
					: 0,

		},

		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, { resetForm }) => {
			let obj = {
				id: 1,
				video_url: values.video_url ? values.video_url : "",
				status: +values.status,
			};
			let formData = new FormData();

			Object.keys(obj).forEach((key) => {
				return formData.append(key, obj[key]);
			});

				dispatch(
					updateData({ data: formData })
				).then((response) => {
					if (response?.payload?.status_code === 200) {
						resetForm();
						closeModal?.();
						refetch();
						dispatch(
							updateConfig((state) => {
								state.showCreateModal = false;
							})
						);
						dispatch(
							updateConfig((state) => {
								state.clearSelection = true;
							})
						);

						toast.success(response?.payload?.message);
					} else if (response?.payload?.status_code === 400) {
						formik.setErrors(response?.payload?.message);
					} else toast.error(response?.payload?.message);
				});
		},
	});


  return {
    formik,
    selectedItemsDetails,
  };
};

export default useEditVideo;
