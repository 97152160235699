import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetBulkEnquiryListDataQuery, useUpdateTableFeildsDataMutation } from "../../../store/queries/bulkEnquiries";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/bulkEnquiries/bulkEnquirySlice";

const useBulkEnquiry = () => {

     const menuState = useSelector(state => state.bulkEnquiry)
     const { tableFields, showEditModal } = useSelector((state) => state.global);
     const dispatch = useDispatch();
     const [skip, setSkip] = useState(true);

     const [updateTableFeildsData] = useUpdateTableFeildsDataMutation();
     const {
          data: mainData = {},
          isFetching,
          isLoading,
          refetch,
     } = useGetBulkEnquiryListDataQuery({
          sort_by: menuState.sortBy,
          sort_order: menuState.sortOrder,
          search: menuState.search,
          per_page: menuState.currentPageSize,
          page: menuState.currentPage,
          is_active: menuState.is_active
     });

     let paginationOptions = [
          { label: "10 Per page", value: 10 },
          { label: "20 Per page", value: 20 },
          { label: "30 Per page", value: 30 },
     ];

     const handleSearch = (e) => {
          dispatch(
               updateConfig((state) => {
                    state.search = e.target.value;
               })
          );
     };

     const handleEditClick = () => {
          dispatch(
               globalUpdateConfig((state) => {
                    state.showEditModal = true;
               })
          );
     };

 

     const handleSort = (label) => {
          if (menuState.sortBy === label) {
               dispatch(
                    updateConfig((state) => {
                         state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
                    })
               );
          } else {
               dispatch(
                    updateConfig((state) => {
                         state.sortBy = label;
                         state.sortOrder = "asc";
                    })
               );
          }
     };
     const getRow = (feild, data) => {
          const rows = {
               common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
          };

          return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
     };

     const handlePagination = (page) => {
          dispatch(
               updateConfig((state) => {
                    state.currentPage = page;
               })
          );
     };

     const handlePageSize = ({ value: page_size }) => {
          dispatch(
               updateConfig((state) => {
                    state.currentPageSize = page_size;
                    state.currentPage = 1;
               })
          );
     };
     const closeEditModal = () => {
          setSkip(() => true);
          dispatch(
               globalUpdateConfig((state) => {
                    state.showEditModal = false;
               })
          );
          dispatch(
               updateConfig((state) => {
                    state.selectedItemsDetails = "";
               })
          );
     };

     let actionOptions = [
          { label: "Edit Columns", value: 0 },
        ];
      
        const handleActionChange = (item) => {
          if (item?.value === 0) {
            dispatch(
              globalUpdateConfig((state) => {
                state.showEditModal = true;
              })
            );
          }
        };

  return {mainData,
    menuState,
    isLoading,
    isFetching,
    handleSearch,
    handleEditClick,
    handleSort,
    getRow,
    currentPage:menuState.currentPage,
    paginationOptions,
    handlePagination,
    handlePageSize,
    showEditModal,
    closeEditModal,
    refetch,
    updateTableFeildsData,
    handleActionChange,
    actionOptions
  }
}

export default useBulkEnquiry
