import { useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { getFormatedDate } from "../../utils/functions/table";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
	updateConfig,
	syncToEship,
	synToShip,
	bulkDownload,
	GetBasicData,
	pickDownload
} from "../../store/slices/Orders/ordersSlice.js";
import { useGetListDataQuery, useUpdateTableFieldsDataMutation } from "../../store/queries/orders";
import { toast } from "react-toastify";
import Style from './orders.module.scss';
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useReactToPrint } from "react-to-print";


const useOrders = ({ dashboard }) => {

	const dispatch = useDispatch();
	const printRef = useRef();
	const navigate = useNavigate();
	const menuState = useSelector((state) => state.orders);

	const { showCreateModal, selctedId } = useSelector((state) => state.orders);
	const { tableFields, showEditModal } = useSelector((state) => state.global);

	const [isMultiSelected, setIsMultiSelected] = useState([]);

	const [showImageModal, setShowImageModal] = useState(false);

	const [imageData, setImageData] = useState("");


	const [selectedOption, setSelectedOption] = useState(null);
	const [showDownloadConfirm, setShowDownloadConfirm] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [printData, setPrintData] = useState(false);
	const [pickListData, setPickListData] = useState(false);
	const [basicData, setBasicData] = useState([]);
	const [PdfLoading, setPdfLoading] = useState(false);

	const [selectedData, setSelectedData] = useState([]);
	const [buttonName, setButtonName] = useState("Yes");


	const tableRef = useRef(null);

	const {
		data: mainData = {},
		isFetching,
		isSuccess,
		isLoading,
		refetch,
	} = useGetListDataQuery({
		sort_by: menuState.sortBy,
		sort_order: menuState.sortOrder,
		search: menuState.search,
		per_page: menuState.currentPageSize,
		page: menuState.currentPage,
		from: getFormatedDate(menuState.startDate),
        to: getFormatedDate(menuState.endDate),
		sort_by_status: menuState?.selectedStatus?.value,
	});


	useEffect(() => {

		dispatch(GetBasicData())
		  .unwrap()
		  .then((result) => {
	setBasicData([
				...[
				  {
					label: "All",
					value: "",
				  },
				],
				...result.data?.map((item) => ({
				  label: item.name,
				  value: item.status_id,
				})),
			  ]);
			  dispatch(
				updateConfig((state) => {
				  state.selectedStatus = {
					label: "All",
					value: "",
				  };
				})
			  );
		  })
		  .catch((err) => { });
	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, []);

	const [date, setDate] = useState({
		startDate: menuState.startDate,
		endDate: menuState.endDate,
		key: "selection",
	  });


	useEffect(() => {
		if (menuState.clearSelection) {
			dispatch(
				updateConfig((state) => {
					state.clearSelection = false;
				})
			);
		}
		// eslint-disable-next-line
	}, [menuState.clearSelection]);



	let paginationOptions = [
		{ label: "10 Per page", value: 10 },
		{ label: "20 Per page", value: 20 },
		{ label: "30 Per page", value: 30 },
	];


	const [updateBrancheFields] = useUpdateTableFieldsDataMutation();


	const handleSort = (label) => {
		if (menuState.sortBy === label) {
			dispatch(
				updateConfig((state) => {
					state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
				})
			);
		} else {
			dispatch(
				updateConfig((state) => {
					state.sortBy = label;
					state.sortOrder = "asc";
				})
			);
		}
	};

	const handleActionSetStatus = (Status) => {
		dispatch(
			updateConfig((state) => {
			  state.selectedStatus = Status;
			})
		  );
	}

	const handleSearch = (e) => {
		dispatch(
			updateConfig((state) => {
				state.search = e.target.value;
			})
		);
	};

	const handleEditClick = () => {
		dispatch(
			globalUpdateConfig((state) => {
				state.showEditModal = true;
			})
		);
	};


	const handleDownload = (data) => {
		setSelectedData(data)
	  setShowDownloadConfirm(true);
	};

	const closeEditModal = () => {
	//   setSkip(() => true);
	  dispatch(
	    globalUpdateConfig((state) => {
	      state.showEditModal = false;
	    })
	  );
	  dispatch(
	    updateConfig((state) => {
	      state.selectedItemsDetails = "";
	    })
	  );
	};

	const [isUrgent, setIsUrgent] = useState(false);

	const getRow = (feild, data) => {
		const rows = {
			common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
			order_num: (feild, data) => {
				return (
					<div className="pro-d-flex pro-justify-between pro-gap-2 pro-items-center">
						<p
							className={`pro-mb-0 pro-pnt`}
							onClick={() => {
								navigate({
									pathname: `/orders/order-details/${data?.id}`,
								});

								sessionStorage.setItem("active", `${data?.id}`);
							}}
						>
							{data?.[feild]} 
							{/* <span>{isUrgent ? '!' : ''}</span> */}
						</p>
						{checkDelay(data?.created_at) && (
							<span class={`${Style.order_id_danger} pro-d-flex pro-justify-center pro-items-center`}>
								<span class="material-symbols-outlined"> exclamation </span>
							</span>
						)}
					</div>
				);
			},
			status: (feild, data) => (
				<p className={`pro-mb-0`}>
				  {data.status === "Placed" ? (
					<span
					  className={`pro-badge placed`}
					>
					  {data[feild]}
					</span>
				  ) : data.status === "QcCheck" ? (
					<span className={`pro-badge qcCheck`}>
					  {data[feild]}
					</span>
				  )  : data.status === "Manufacturing" ? (
					<span className={`pro-badge manufacturing`}>
					  {data[feild]}
					</span>
				  ) : data.status === "Shipped" ? (
					<span className={`pro-badge shipped`}>
					  {data[feild]}
					</span>
				  )   : data.status === "Shipped" ? (
					<span className={`pro-badge shipped`}>
					  {data[feild]}
					</span>
				  ) : data.status === "Delivered" ? (
					<span className={`pro-badge delivered`}>
					  {data[feild]}
					</span>
				  ) : (
					<span
					  className={`pro-badge cancelled`}
					>
					  {data[feild]}
					</span>
				  )}
				</p>
			  ),
		};

		return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
	};

	const checkDelay = (givenDate) => {
		const isThreeDaysApart = (formattedDate) => {
			const currentDate = new Date();
			const differenceInTime = currentDate - formattedDate;
			const differenceInDays = differenceInTime / (1000 * 3600 * 24);
			return Math.abs(differenceInDays) > 3;
		  };
		  const yourGivenDate = new Date(givenDate);
		  if (isThreeDaysApart(yourGivenDate)) {
			return true
		  }

	}

	const handlePagination = (page) => {
		dispatch(
			updateConfig((state) => {
				state.currentPage = page;
			})
		);
	};

	const handlePageSize = ({ value: page_size }) => {
		dispatch(
			updateConfig((state) => {
				state.currentPageSize = page_size;
				state.currentPage = 1;
			})
		);
	};

	let actionOptions = [
		{ label: "Edit Columns", value: 0 },
	];

	const handleActionChange = (item) => {
		if (item?.value === 0) {
			dispatch(
				globalUpdateConfig((state) => {
					state.showEditModal = true;
				})
			);
		}
	};

	const handleClearClick = () => {
		setDate({
			startDate: new Date(),
			endDate: addDays(new Date(), 7),
			key: "selection",
		});
		dispatch(
			updateConfig((state) => {
				state.currentPage = 1;
				state.currentPageSize = 10;
				state.sortBy = "";
				state.sortOrder = "";
				state.search = "";
				state.showCreateModal = false;
				state.showInvoiceModal = false;
				state.selctedId = "";
				state.is_active = null;
			})
		);
	};

	const handleChange = (selectedOption) => {
		// setSelectedOption(selectedOption);
		dispatch(
			updateConfig((state) => {
				state.is_active = selectedOption.value;
			})
		);
	};

	const handleDownloadAction = async () => {
	  if (showDownloadConfirm) {
		// handleDownloadClick();
	          setShowDownloadConfirm(false);
			  setShowConfirm(true)
	  } else {
	    setShowDownloadConfirm(false);
	  }
	};

	const handleSpecificAction = async () => {
		if (showConfirm) {
			if(selectedOption === 1) {
				handleDownloadClick();
			}
			else{
				handlePickListClick();
			}
			
				setShowConfirm(false);
		  } else {
			setShowConfirm(false);
		  }
	}



	const handleDashboardRedirect = () => {
		navigate("/orders/");
		setTimeout(() => {
			dispatch(
				updateConfig((state) => {
					state.currentPage = 1;
				})
			);
		}, 500);
	};

	const handleSelectedIds = (ids) => {
		setIsMultiSelected(ids);
	};
 const handlePickListDownLoad = (data) => {
	const obj = {
		"order_id" : data
	  }
	  dispatch(pickDownload(obj))
			.unwrap()
			.then((result) => {
				if (result?.success) {
					setPickListData(result?.data)
					// handleDownload();
					handleDownloadAction();
				} else {
					toast.error(result?.message);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
 }

	const handleBulkDownLoad = (data) => {
	  const obj = {
		"order_id" : data
	  }
	  dispatch(bulkDownload(obj))
			.unwrap()
			.then((result) => {
				if (result?.success) {
					setPrintData(result?.data)
					// handleDownload();
					handleDownloadAction();
				} else {
					toast.error(result?.message);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
	
	};

	const handleSyncEship = () => {
		dispatch(syncToEship())
			.unwrap()
			.then((result) => {
				if (result?.success) {
					toast.success(result?.message);
				} else {
					toast.error(result?.message);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
	}
	const handleSyncShip = () => {
		dispatch(synToShip())
			.unwrap()
			.then((result) => {
				if (result?.success) {
					toast.success(result?.message);
				} else {
					toast.error(result?.message);
				}
			})
			.catch((err) => {
				toast.error(err);
			});
	}

	const handleDateRangeChange = (range) => {
		const startDate = range[0];
		const endDate = range[1];
	
		setDate({
		  startDate,
		  endDate,
		});
	
		dispatch(
		  updateConfig((state) => {
			state.currentPage = 1;
			state.startDate = startDate;
			state.endDate = endDate;
		  })
		);
	  };

	const handleDownloadClick = () => {
		setPdfLoading(true)
		
		const element = printRef.current;
		setTimeout(() => {
			html2pdf(element, {
				margin: [20, 0, 10, 0],
				filename: `${
				  "Bulk_" +
				  moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
				}.pdf`,
				// image: { type: "jpeg", quality: 1 },
				html2canvas: {
				//   width: 775,
				  dpi: 100,
				  letterRendering: true,
				  useCORS: true,
				  logging: true,
				  scale: 2,
				},
				jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
				pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
				DisablePdfCompression: true,
			  });
		  }, 200); 
		  setPdfLoading(false);

		
	  };

	  const handlePickListClick = () => {
		setPdfLoading(true)
		
		const element = printRef.current;
		setTimeout(() => {
			html2pdf(element, {
				margin: [0, 0, 10, 0],
				filename: `${
				  "PickList_" +
				  moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
				}.pdf`,
				// image: { type: "jpeg", quality: 1 },
				html2canvas: {
				  width: 775,
				  dpi: 100,
				  letterRendering: true,
				  useCORS: true,
				  logging: true,
				  scale: 1.5,
				},
				jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
				pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
				DisablePdfCompression: true,
			  });
		  }, 500); 
		  setPdfLoading(false);

		
	  };

	  const closeConfirmModal = () => {
		setShowDownloadConfirm(false);
	  };

	  const handleFuction = (text) => {
		
		if( text === 1) {
			handleBulkDownLoad(selectedData);
		}
		else {
			handlePickListDownLoad(selectedData);
		}
		setSelectedOption(text)
	  }

	return {
		mainData,
		menuState,
		currentPage: menuState.currentPage,
		showCreateModal,
		isFetching,
		isLoading,
		date,
		showEditModal,
		tableFields,
		paginationOptions,
		selctedId,
		actionOptions,
		showImageModal,
		handleActionChange,
		refetch,
		updateBrancheFields,
		handleSort,
		handleSearch,
		getRow,
		handleEditClick,
		handlePagination,
		handlePageSize,
		handleClearClick,
		handleChange,
		tableRef,
		setShowDownloadConfirm,
		handleDashboardRedirect,
		handleSelectedIds,
		handleSyncEship,
		handleSyncShip,
		handleDateRangeChange,
		date,
		handleBulkDownLoad,
		printData,
		pickListData,
		printRef,
		handleDownload,
		handleDownloadAction,
		showDownloadConfirm,
		basicData,
		handleActionSetStatus,
		PdfLoading,
		closeConfirmModal,
		handleFuction,
		showConfirm,
		setShowConfirm,
		handleSpecificAction,
		buttonName
	};
};

export default useOrders;
