import React from "react";
import Style from "./userDetail.module.scss";
import {
  MultiColumnTable,
  SimpleReport,
  ModalLayout,
  DataContainer,
  WarningBox,
} from "@wac-ui-dashboard/wac_component_library";
import useUserDetails from "./useUserDetails";
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer";

const UserDetails = () => {
  const {
    profileData,
    userDetails,
    occupationDetails,
    familyDetails,
    headerDetails,
    isFetching,
    showModal,
    activeProfile,
    contactDetails,
    showImageVerificationModal,
    showDocumentVerificationModal,
    verificationState,
    ImageVerifyStatus,
    IdVerifyStatus,
    hasUpdatePermission,
    hasVerifyPermission,
    hasUploadsViewPermission,
    viewId,
    imageData,
    setViewId,
    setImageData,
    closeIdModal,
    setShowDocumentVerificationModal,
    setShowImageVerificationModal,
    closeModal,
    closeDocumentModal,
    refetch,
    onProfileVerifyClick,
    onProfileIdVerifyClick,
    handleCloseModal,
    handleEduClick,
    handleAboutClick,
    handleJobClick,
    handleFamilyClick,
    handleContactClick,
  } = useUserDetails();

  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          
          <div className={`col-12 ${Style.overview_wrap_list}`}>
            <div className="row gx-2 gx-xxl-6">
              <div className={`col-12 ${Style.overview_wrap_list_item}`}>
                <div className="pro-m-4 pro-mx-5">
                  <MultiColumnTable
                    minWidth={`30%`}
                    title={"User Details"}
                    data={userDetails}
                    extraClassNames={`${Style.table_wrap}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetails;
