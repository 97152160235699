import Assets from "../../../assets/Assets";
import Style from "./adminProfileCard.module.scss";

const AdminProfileCard = ({
  userData,
  profilefileInputRef,
  handleProfileImage,
  imagePreview,
}) => {
  const handleClick = () => {
    profilefileInputRef.current.click();
  };

  return (
    <div className={Style.root}>
      <div className={Style.img_wrap}>
        <figure className="pro-mb-0">
          {imagePreview ? (
            <img src={imagePreview} alt="staff_image" />
          ) : imagePreview === null && userData?.full_name === "Super Admin" ? (
            <img src={Assets.ACCOUNT_CIRCLE} alt="staff_image" />
          ) : (
            <img src={Assets.GENDER_IMAGE} alt="staff_image" />
          )}
        </figure>
        {userData?.full_name !== "Super Admin" ? (
          // <span className={Style.editProfile} onClick={handleClick}>
          //   {" "}
          //   <span class="material-symbols-outlined">edit</span>
          // </span>
          <></>
        ) : (
          <></>
        )}
      </div>
      <div className="pro-ps-4 pro-pt-1 pro-pb-2 pro-d-flex pro-flex-column">
        {userData?.full_name && (
          <h4 className="pro-ttl h4 pro-mb-1">
            {(userData?.full_name ?? " ") }
          </h4>
        )}
        {<p className="pro-mb-2">{userData?.user_type_name}</p>}
        <ul
          className={`pro-d-flex pro-align-center ${
            userData?.branch?.name ? Style.list : ""
          }`}
        >
          <li>{userData?.id ?? ""}</li>
          <li>{userData?.branch?.name ?? ""}</li>
        </ul>
      </div>

      <div className="input-wrap pro-mb-4">
        <div className="input-drag">
          <input
            type="file"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${" error"}`}
            style={{ display: "none" }}
            id="profile"
            name="profile"
            // onChange={(e) => handleProfileImage(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminProfileCard;
