import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
	updateConfig,
	updateData
} from "../../../../store/slices/CompanyDetails/companyDetailsSlice.js";
import { emailRegExp, phoneRegExp } from "../../../../utils/functions/table.js";

const useEditCompanyDetails = ({ closeModal, refetch }) => {


	const { selectedItemsDetails } = useSelector((state) => state.companyDetails);
	const dispatch = useDispatch();

	const validation = Yup.object({
		address: Yup.string()
			.min(3, "Minimum 3 characters required")
			.required("*Required"),
		address_line_2: Yup.string()
			.min(3, "Minimum 3 characters required")
			.required("*Required"),
		email: Yup.string()
			.matches(emailRegExp, "Please enter a valid email")
			.required("*Required"),

		phone: Yup.string()
			.matches(phoneRegExp, "Please enter a valid phone number")
			.min(10, "Phone number must be 10 digits")
			.max(10, "Phone number must be 10 digits")
			.required("*Required"),
	});

	const formik = useFormik({
		initialValues: {
			id: 1,
			address: selectedItemsDetails !== ""
				? selectedItemsDetails?.address !== null
					? selectedItemsDetails?.address
					: ""
				: "",
			address_line_2: selectedItemsDetails !== ""
				? selectedItemsDetails?.address_line_2 !== null
					? selectedItemsDetails?.address_line_2
					: ""
				: "",
			email: selectedItemsDetails !== ""
				? selectedItemsDetails?.email !== null
					? selectedItemsDetails?.email
					: ""
				: "",
			phone: selectedItemsDetails !== ""
				? selectedItemsDetails?.phone !== null
					? selectedItemsDetails?.phone
					: ""
				: "",

		},

		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, { resetForm }) => {
			let obj = {
				id: 1,
				address: values.address ? values.address : "",
				address_line_2: values.address_line_2 ? values.address_line_2 : "",
				email: values.email ? values.email : "",
				country_code: "+91",
				phone: values.phone ? values.phone : "",
			};
			let formData = new FormData();

			Object.keys(obj).forEach((key) => {
				return formData.append(key, obj[key]);
			});

			dispatch(
				updateData({ data: formData })
			).then((response) => {
				if (response?.payload?.status_code === 200) {
					resetForm();
					closeModal?.();
					refetch();
					dispatch(
						updateConfig((state) => {
							state.showCreateModal = false;
						})
					);
					dispatch(
						updateConfig((state) => {
							state.clearSelection = true;
						})
					);

					toast.success(response?.payload?.message);
				} else if (response?.payload?.status_code === 400) {
					formik.setErrors(response?.payload?.message);
				} else toast.error(response?.payload?.message);
			});
		},
	});

	return {
		formik,
		selectedItemsDetails,
	};
};

export default useEditCompanyDetails;
