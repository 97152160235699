import { useSearchParams } from "react-router-dom";
import { useRef, useState } from "react";
import { useGetOrderDetailDataQuery, useGetProductDetailDataQuery } from "../../../store/queries/orders";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const useInvoice = () => {
  const [params] = useSearchParams();
  const printRef = useRef();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetOrderDetailDataQuery({
    order_id: activeProfile,
  });

  const handleDownloadClick = () => {
    const element = printRef.current;

    html2pdf(element, {
      margin: [0, 0, 10, 0],
      filename: `${
        profileData?.data?.result?.order_num +
        "_" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 775,
        height: 1560,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  const handlePrintClick = useReactToPrint({
    content: () => printRef.current,
  });

  // const actions = [
  //   {
  //     label: "Download",
  //     handleClick: handleDownloadClick,
  //     icon: <span className="material-symbols-outlined"> cloud_download </span>,
  //   },
  //   {
  //     label: "Print",
  //     handleClick: handlePrintClick,
  //     icon: <span className="material-symbols-outlined"> print </span>,
  //   },
  // ];

  const billingDetailLabel = {
    tax: "Tax",
    discount: "Discount",
    shipping_charge: "Shipping Charge",
    extra_charge: "Extra Charge",
    subTotal: "Sub Total"
  };

  const billingDetailValue = {
    tax: `${
      profileData?.data?.result?.invoice?.tax ?? "Not specified"
    }`,
    discount: `${profileData?.data?.result?.invoice?.discount ?? "Not specified"}`,
    shipping_charge: `${
        profileData?.data?.result?.invoice?.shipping_charge ?? "Not specified"
      }`,
      extra_charge: `${
        profileData?.data?.result?.invoice?.extra_charge ?? "Not specified"
      }`,
      subTotal: `${
        profileData?.data?.result?.grand_total ?? "Not specified"
      }`,
  };

  const billingDetails = Object.keys(billingDetailValue).map((key) => {
    return {
      label: billingDetailLabel[key],
      value:
        typeof billingDetailValue[key] === "string"
          ? billingDetailValue?.[key]
          : typeof billingDetailValue[key] === "number"
          ? billingDetailValue?.[key]
          : billingDetailValue?.[key]?.name,
    };
  });

  

  return {
    invoiceData: profileData?.data?.result,
    billingDetails,
    isFetching,
    activeProfile,
    // actions,
    printRef,
    handleDownloadClick,
    handlePrintClick
  };
};

export default useInvoice;
