import {
  HeadingGroup,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useReturnPolicy from "./useReturnPolicy";
import EditPolicy from "./EditPolicy";
// import BannerForm from "./BannerForm";
import Style from './returnPolicy.module.scss';

const ReturnPolicy = () => {
  const {
    mainData,
    menuState,
    refetch,
    handleEditClick,
    closeModal,
  } = useReturnPolicy();

  const parse = require("html-react-parser");
  const content = mainData?.data?.result?.description ?? "";


  return (
    <>
      {/* <h3>Return Policy</h3>
      < onClick={handleEditClick}>{parse(content)}<span className="material-symbols-outlined">
              edit_square
            </span></> */}

<HeadingGroup
        title={"Return Policy"}
        extraClassName={`pro-mb-5`}
      >
        <button className="pro-btn pro-btn-primary pro-items-center" onClick={handleEditClick}>
          <span class="material-symbols-outlined">edit_square</span>
          <span>Edit</span>
        </button>
      </HeadingGroup>
      <div className={`pro-pb-5 ${Style.admin_content_area}`}>
        {parse(content)}
      </div >
    
    <ModalLayout
        centered={false}
        show={menuState.showEditModal}
        handleClose={() => closeModal()}
        title={menuState?.is_edit ? "Update Return Policy" : ""}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle ={{root: Style.modal_root}}
      >
        <EditPolicy closeModal={() => closeModal()} refetch={refetch} />
      </ModalLayout>
    </>
  );
};

export default ReturnPolicy;
