import {
  HeadingGroup,
  ModalLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";

import useRoles from "./useRoles";
import CreateRoleModal from "./CreateRoleModal/index";
const Roles = () => {
  const {
    showModal,
    roles,
    rolesState,
    isFetching,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission,
    hasViewPermission,
    handleCreateClick,
    handleDelete,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
  } = useRoles();
  return (
    <>
      {" "}
      <HeadingGroup
        title={"User Types"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission === true ?? false  ? "Create" : "Create"}
        handleClick={handleCreateClick}
      />{" "}
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {" "}
        <Table
          data={roles?.data?.result || []}
          deletable={false}
          uniqueID={"id"}
          fields={roles?.data?.fields}
          showCheckBox={hasEditPermission ? true : true}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          handleEdit={handleEdit}
          loading={isFetching}
          perpage={rolesState?.currentPageSize}
          assignable={false}
          multiSelect={false}
        />{" "}
        <ModalLayout
          centered={false}
          show={showModal}
          handleClose={closeModal}
          title={"Create Role"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <CreateRoleModal />{" "}
        </ModalLayout>{" "}
      </div>{" "}
    </>
  );
};

export default Roles;
