import { useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { getFormatedDate } from "../../../utils/functions/table";
import { useGetEnquiryListDataQuery, useUpdateTableFeildsDataMutation } from "../../../store/queries/enquiries"
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { addDays } from "date-fns";
import { updateConfig } from "../../../store/slices/Enquiries/enquirySlice";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const useEnquiry = () => {

     const menuState = useSelector(state => state.enquiry)
     const { tableFields, showEditModal } = useSelector((state) => state.global);
     const dispatch = useDispatch();
     const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
     const [selectedItemID, setSelectedItemID] = useState("");
     const [showImageModal, setShowImageModal] = useState(false);
     const [imageData, setImageData] = useState("");
     const [skip, setSkip] = useState(true);

     const [updateTableFeildsData] = useUpdateTableFeildsDataMutation();
     const {
          data: mainData = {},
          isFetching,
          isLoading,
          refetch,
     } = useGetEnquiryListDataQuery({
          sort_by: menuState.sortBy,
          sort_order: menuState.sortOrder,
          search: menuState.search,
          per_page: menuState.currentPageSize,
          page: menuState.currentPage,
          is_active: menuState.is_active
     });

     let paginationOptions = [
          { label: "10 Per page", value: 10 },
          { label: "20 Per page", value: 20 },
          { label: "30 Per page", value: 30 },
     ];

     const [date, setDate] = useState({
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: "selection",
     });

     const handleDateChange = (range) => {
          setDate(range);
          dispatch(
               updateConfig((state) => {
                    state.currentPage = 1;
                    state.startDate = getFormatedDate(range.startDate);
                    state.endDate = getFormatedDate(range.endDate);
                    state.clearSelection = true;
               })
          );
     };

     const handleSearch = (e) => {
          dispatch(
               updateConfig((state) => {
                    state.search = e.target.value;
               })
          );
     };

     const handleEditClick = () => {
          dispatch(
               globalUpdateConfig((state) => {
                    state.showEditModal = true;
               })
          );
     };

     const handleDelete = async (ids) => {
          setShowDeleteConfirm(true);
          setSelectedItemID(ids);

     };

     // const handleEditAction = (data) => {
     //      setSkip(() => false);
     //      dispatch(getSizeDataByID(data?.[0]))
     //      dispatch(
     //        updateConfig((state) => {
     //          state.showCreateModal = true;
     //          state.is_edit = true;
     //          state.selectedId = data?.[0];
     //        })
     //      );
     //    };

     const handleSort = (label) => {
          if (menuState.sortBy === label) {
               dispatch(
                    updateConfig((state) => {
                         state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
                    })
               );
          } else {
               dispatch(
                    updateConfig((state) => {
                         state.sortBy = label;
                         state.sortOrder = "asc";
                    })
               );
          }
     };
     const getRow = (feild, data) => {
          const rows = {
               common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
               image: (feild, data) => {
                    return (
                         <div className="no-wrap">
                              <IconText
                                   icon={
                                        <span className="material-symbols-outlined x4">imagesmode</span>
                                   }
                                   title={`${"View Photo"}`}
                                   onClick={() => handleViewImage(data?.image_url)}
                              />
                         </div>
                    );
               },
          };

          return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
     };

     const handleViewImage = (data) => {
          setShowImageModal(() => true);
          setImageData(data);
     };

     const handlePagination = (page) => {
          dispatch(
               updateConfig((state) => {
                    state.currentPage = page;
               })
          );
     };

     const handlePageSize = ({ value: page_size }) => {
          dispatch(
               updateConfig((state) => {
                    state.currentPageSize = page_size;
                    state.currentPage = 1;
               })
          );
     };
     const closeEditModal = () => {
          setSkip(() => true);
          dispatch(
               globalUpdateConfig((state) => {
                    state.showEditModal = false;
               })
          );
          dispatch(
               updateConfig((state) => {
                    state.selectedItemsDetails = "";
               })
          );
     };

     let actionOptions = [
          { label: "Edit Columns", value: 0 },
        ];
      
        const handleActionChange = (item) => {
          if (item?.value === 0) {
            dispatch(
              globalUpdateConfig((state) => {
                state.showEditModal = true;
              })
            );
          }
        };


     return {
          mainData,
          menuState,
          isLoading,
          isFetching,
          handleDateChange,
          handleSearch,
          handleEditClick,
          handleDelete,
          // handleEditAction,
          handleSort,
          getRow,
          currentPage: menuState.currentPage,
          paginationOptions,
          handlePagination,
          handlePageSize,
          showEditModal,
          closeEditModal,
          refetch,
          updateTableFeildsData,
          handleActionChange,
          actionOptions
     }
}

export default useEnquiry
