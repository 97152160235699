import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useGetOrderDetailDataQuery } from "../../../store/queries/orders";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

const useOrderDetails = () => {
	const dispatch = useDispatch();
	const [params] = useSearchParams();
	const printRef = useRef();

	const productState = useSelector((state) => state.orders);

	const activeProfile =
		params.get("active") ?? sessionStorage.getItem("active");

	const {
		isFetching,
		isSuccess,
		isError,
		refetch,
	} = useGetOrderDetailDataQuery({
		order_id: activeProfile,
	});

	const [sizeName, setSizeName] = useState()

	const handleDownloadClick = () => {
		fetch(productState?.productDetails?.product_image)
			.then((response) => response.blob())
			.then((blob) => {
				// Create a Blob URL for the image blob
				const blobUrl = URL.createObjectURL(blob);

				// Create an anchor element
				const downloadLink = document.createElement('a');

				// Set the href attribute to the Blob URL
				downloadLink.href = blobUrl;

				// Set the download attribute with the desired filename
				downloadLink.download = productState?.productDetails?.product_name || 'downloaded_image';

				// Append the anchor element to the document
				document.body.appendChild(downloadLink);

				// Trigger a click on the anchor element to start the download
				downloadLink.click();

				// Remove the anchor element from the document
				document.body.removeChild(downloadLink);

				// Revoke the Blob URL to free up resources
				URL.revokeObjectURL(blobUrl);
			})
			.catch((error) => {
				toast.error('Error downloading image:', error);
			});
	};

	const handlePrintClick = useReactToPrint({
		content: () => printRef.current,
		
	});

	const actions = [
		{
			label: "Download",
			handleClick: handleDownloadClick,
			icon: <span className="material-symbols-outlined"> cloud_download </span>,
		},
		{
			label: "Print",
			handleClick: handlePrintClick,
			icon: <span className="material-symbols-outlined"> print </span>,
		},
	];

	const handleImageDownloadClick = (imageUrl, imageName) => {
		fetch(imageUrl)
			.then((response) => response.blob())
			.then((blob) => {
				// Create a Blob URL for the image blob
				const blobUrl = URL.createObjectURL(blob);

				// Create an anchor element
				const downloadLink = document.createElement('a');

				// Set the href attribute to the Blob URL
				downloadLink.href = blobUrl;

				// Set the download attribute with the desired filename
				downloadLink.download = imageName || 'downloaded_image';

				// Append the anchor element to the document
				document.body.appendChild(downloadLink);

				// Trigger a click on the anchor element to start the download
				downloadLink.click();

				// Remove the anchor element from the document
				document.body.removeChild(downloadLink);

				// Revoke the Blob URL to free up resources
				URL.revokeObjectURL(blobUrl);
			})
			.catch((error) => {
				toast.error('Error downloading image:', error);
			});

	};

	const getName = (index) => {
		let size_name = `${productState?.productDetails?.cluster_details[index]?.height}*${productState?.productDetails?.cluster_details[index]?.width}`;
		setSizeName(size_name);
	}

	useEffect(() => {
		if (productState?.productDetails?.cluster_details) {
			getName(0);
		}
	}, [productState?.productDetails?.cluster_details]);



	return {
		productData: productState?.productDetails,
		isProdFetching: productState?.isProductLoading,
		isFetching,
		isSuccess,
		isError,
		activeProfile,
		refetch,
		actions,
		printRef,
		handleImageDownloadClick,
		moment,
		sizeName,
		getName

	};
};

export default useOrderDetails;
